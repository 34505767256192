<template>
  <div class="standard-service-wrap" v-lazy-animate>
    <LineWrap :titleObj="titleObj"></LineWrap>
    <Tab></Tab>
  </div>
</template>
<script>
import LineWrap from '../../components/LineWrap'
import Tab from './component/Tab/index'
export default {
  name: 'standardService',
  components: {
    LineWrap,
    Tab
  },
  data () {
    return {
      titleObj: {
        title: 'HIMO标准服务',
        tip: '极致服务 最好的你'
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .standard-service-wrap {
    background-color: #fff;
    padding: 80px 0 88px;
  }
</style>
