<template>
  <div class="footer-wrap">
    <div class="footer-content">
      <div class="company-info">
        <div class="company-logo">
          <img src="~/assets/image/icon/himo.png" alt />
        </div>
        <div class="company-content">
          <div class="content">
            <div class="content-item">
              <div class="content-cell font-blod">企业连接</div>
              <div class="content-cell font-blod">热门城市</div>
              <div class="content-cell font-blod">关于HIMO</div>
              <div class="content-cell font-blod">联系海马体</div>
            </div>
            <div class="content-item">
              <div class="content-cell" onclick="window.open('https://www.mainto.cn')">
                <p>海马体集团官网</p>
              </div>
              <div class="content-cell">
                <span class="cell-padding" @click="jumpCity(510100000)">成都</span>
                <span class="cell-hover" @click="jumpCity(310000000)">上海</span>
              </div>
              <div class="content-cell" @click="jumpProblem">
                <p>常见问题</p>
              </div>
              <div class="content-cell font-phone">
                <p>400-068-5666</p>
              </div>
            </div>
            <div class="content-item">
              <div class="content-cell" @click="jumpBusiness">
                <p>海马体企业</p>
              </div>
              <div class="content-cell">
                <span class="cell-padding" @click="jumpCity(110000000)">北京</span>
                <span class="cell-hover" @click="jumpCity(420100000)">武汉</span>
              </div>
              <div class="content-cell" @click="jumpStore">
                <p>门店信息</p>
              </div>
              <div class="content-cell font-phone"></div>
            </div>
            <div class="content-item">
              <div class="content-cell"/>
              <div class="content-cell">
                <span class="cell-padding" @click="jumpCity(500000000)">重庆</span>
                <span class="cell-hover" @click="jumpCity(320100000)">南京</span>
              </div>
              <div class="content-cell" @click="jumpBusiness">
                <p>企业合作</p>
              </div>
              <div class="content-cell pos-img">
                <div class="code-content">
                  <img class="code-icon" src="~/assets/image/icon/weibo.png" />
                  <div class="code">
                    <img src="~/assets/image/icon/weibo-code.png" alt />
                    <p class="code-attention">关注微博</p>
                  </div>
                </div>
                <div class="code-content">
                  <img class="code-icon" src="~/assets/image/icon/red-book.png" />
                  <div class="code">
                    <img src="~/assets/image/icon/red-book-code.png" alt />
                    <p class="code-attention">关注小红书</p>
                  </div>
                </div>
                <div class="code-content">
                  <img class="code-icon" src="~/assets/image/icon/douyin.png" />
                  <div class="code">
                    <img src="~/assets/image/icon/douyin-code.png" alt />
                    <p class="code-attention">关注抖音</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-item">
              <div class="content-cell"/>
              <div class="content-cell">
                <span class="cell-padding" @click="jumpCity(330100000)">杭州</span>
                <span class="cell-hover" @click="jumpCity(440300000)">深圳</span>
              </div>
              <div class="content-cell">
                <a onclick="window.open('https://mainto.zhiye.com/Social')">人才招聘</a>
              </div>
              <div class="content-cell">
                <p class="line"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="company-code">
          <img src="~/assets/image/icon/review-code.png" alt />
          <p>扫码预约拍摄</p>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <p>浙公网安备 33011802000662</p>
      <p>
        <a class="jump-page" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备11062584号-3</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    jumpProblem () {
      this.$router.push({
        name: 'problem'
      })
    },
    jumpStore () {
      this.$router.push({
        name: 'storeInfo'
      })
    },
    jumpBusiness () {
      this.$router.push({
        name: 'businessCooperation'
      })
    },
    jumpCity (id) {
      this.$router.push({
        name: 'storeInfo',
        query: {
          id: id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.footer-wrap {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e3e3e6;

  .footer-content {
    position: relative;
    width: 1152px;
    height: 196px;
    margin: 0 auto 62px;
    padding-top: 48px;

    .company-info {
      .company-logo {
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;

        img {
          width: 55px;
        }
      }

      .company-code {
        position: absolute;
        right: 0;
        display: inline-block;
        vertical-align: top;

        img {
          width: 112px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: #45454D;
          text-align: center;
        }
      }

      .company-content {
        display: inline-block;

        .content {
          display: table;

          .content-item {
            display: table-row;
          }

          .content-cell {
            display: table-cell;
            text-align: left;
            padding: 5px 58px;
            font-size: 12px;
            color: #919199;
            cursor: pointer;

            a {
              text-decoration: none;
              color: #919199;
            }

            img {
              display: inline-block;

              &:nth-child(even) {
                margin: 0 17px;
              }
            }

            .cell-padding,
            .cell-hover {
              display: inline-block;
              padding-right: 16px;
              width: 40px;

              &:hover {
                display: inline-block;
                width: 40px;
                color: #000;
              }
            }

            .line {
              width: 28px;
              height: 4px;
              background-color: #f2f2f5;
            }

            &:hover {
              p {
                color: #000;
              }

              a {
                color: #000;
              }
            }
          }

          .pos-img {
            position: absolute;
            bottom: 21px;

            .code-content {
              position: relative;
              display: inline-block;
              padding-right: 5px;

              .code {
                position: absolute;
                width: 174px;
                height: 190px;
                background: url("~assets/image/icon/code-bg.png");
                background-size: 100%;
                background-repeat: no-repeat;
                opacity: 0;
                top: -190px;
                left: -72px;
                z-index: -1;

                img {
                  width: 140px;
                  height: 140px;
                  margin-left: 16px;
                  margin-top: 16px;
                }

                p {
                  text-align: center;
                  margin-top: -2px;
                }
                .code-attention {
                  color: #16161a;
                }
              }

              .code-icon {
                width: 29px;
                height: 29px;
              }
              &:hover {
                .code {
                  opacity: 1;
                  transition: all 1s;
                  z-index: 100;
                }
              }
            }
          }

          .font-blod {
            color: #45454d;
            font-size: 14px;
            font-weight: 600;
            padding: 0 58px 11px;
          }

          .font-phone {
            color: #000;
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .footer-bottom {
    background-color: #f0f0f2;
    color: #919199;
    font-size: 14px;
    padding: 18px 0;

    p {
      display: inline-block;

      .jump-page {
        color: #919199;
        text-decoration: none;
      }
    }

    p:first-child:after {
      display: inline-block;
      content: "";
      height: 18px;
      width: 2px;
      background-color: #919199;
      vertical-align: bottom;
      margin: 0 18px;
    }
  }
}
</style>
