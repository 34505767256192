<template>
  <div class="business-cooperation-wrap">
    <div class="business-cooperation-content" v-lazy-animate>
      <div class="business-cooperation-head">
        <p class="title">HIMO BUSINESS</p>
        <div class="line">
          <span></span>
          <span class="black-color"></span>
          <span class="gray-color"></span>
        </div>
        <div class="desc">
          <p>HIMO BUSINESS是海马体照相馆根据企业市场需求所增设的商务拍摄板块。</p>
          <p>致力于为企业提供“量身定制”的形象包装及福利方案，帮助企业对内宣传企业文化</p>
          <p>对外展示企业形象，打造更高端的企业品牌文化。</p>
        </div>
      </div>
      <div class="business-cooperation-container">
        <div class="product">
          <LineWrap :titleObj="titleObj"></LineWrap>
          <img src="~/assets/image/business/imac.png" alt="" />
          <div class="business-product-intro">
            <div
              class="product-content"
              v-for="(item, index) in productArr"
              :key="index"
              v-lazy-animate="index * 0.2"
            >
              <div class="product-title">
                <p class="product-num">{{ item.num }}</p>
                <div class="product-name">
                  <p>{{ item.name }}</p>
                  <p class="english-name">{{ item.englishName }}</p>
                </div>
              </div>
              <div class="product-desc" :class="item.showNoBorder ? 'show-border' : ''">
                <p>{{ item.intro }}</p>
                <a :href="item.link" target="_blank">查看详情>></a>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-info">
          <div class="contact-content">
            <p class="content-title">联系我们</p>
            <div class="circle">
              <span></span>
              <span></span>
            </div>
            <p class="tip">您可以留下您的信息，或者直接电话/微信联系HIMO Business商务BD</p>
            <div class="contact-method no-inline">
              <img src="~/assets/image/icon/email.png" />
              <p>business@mainto.cn</p>
            </div>
            <img
              class="line-type"
              src="~/assets/image/icon/line.png"
              alt=""
              @click="handleContact"
            />
          </div>
        </div>
        <div class="partner-info">
          <LineWrap :titleObj="partnerObj"></LineWrap>
          <div class="scorll-img">
            <div class="img-show">
              <img src="~/assets/image/icon/grand.png" />
              <img src="~/assets/image/icon/grand.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperation-pop" :class="showModal && 'isShow'">
      <img class="close" src="@/assets/image/icon/close-icon.png" @click="handleClose" />
      <iframe
        ref="cooperation"
        id="cooperation"
        v-if="showModal"
        width="375"
        height="800"
        :src="BUSINESS_FORM"
      >
      </iframe>
    </div>
  </div>
</template>
<script>
import LineWrap from '../../components/LineWrap'
const BUSINESS_FORM = `${process.env.VUE_APP_ENV_HIMO_HOST}business/index/pcOfficial`

export default {
  name: 'businessCooperation',
  components: {
    LineWrap
  },
  data () {
    return {
      titleObj: {
        title: '产品服务',
        tip: '我们是记忆的中转站，留下片刻间的你'
      },
      partnerObj: {
        title: '合作媒体&企业',
        tip: '我们是记忆的中转站，留下片刻间的你'
      },
      productArr: [
        {
          num: '01',
          name: '证件照',
          showNoBorder: false,
          englishName: 'HIMO - ID',
          intro: '一张精致的证件照，是人生的点缀，是真我的展示，是态度的诠释，以神采奕奕的姿态，拥抱工作生活。',
          link: 'https://b.haimati.cn/#/photoProduct?id=certificate'
        },
        {
          num: '02',
          name: '写真类',
          showNoBorder: false,
          englishName: 'Portrait Photo',
          intro: '高级的影调，细腻的纹理，用光定格魅力，展示真我：生活不止有眼前的苟且，还有诗和远方。',
          link: 'https://b.haimati.cn/#/photoProduct?id=portrait'
        },
        {
          num: '03',
          name: '形象职业照',
          showNoBorder: false,
          englishName: 'Occupation Image',
          intro: '笔挺的西装，修身的短裙，对生活的一丝不苟，对工作的认真用心，用职业形象诠释专业与素养，用实力挑战未来。',
          link: 'https://b.haimati.cn/#/photoProduct?id=career'
        },
        {
          num: '04',
          name: '定制服务',
          showNoBorder: false,
          englishName: 'Custom service',
          intro: 'HIMO BUSINESS秉承“跨界合作，品牌共赢”的合作理念，针对高端品牌、大型活动策划、根据不同的方案定制合适的产品，提升市场影响力，创造价值。',
          link: 'https://b.haimati.cn/#/customizedProduct?id=customized'
        },
        {
          num: '05',
          name: '合照类',
          showNoBorder: true,
          englishName: 'Team Profile Photo',
          intro: '无论是亲人、朋友、闺蜜、还是同事，都需要一张好的合影，留下一份隽永的回忆；用一张珍贵的全家福记录幸福，留住温情的回忆；一个团队，一个缩影，一张好的团队照，一个优秀的团队，一个良好的品牌。',
          link: 'https://b.haimati.cn/#/photoProduct?id=group'
        },
        {
          num: '06',
          name: '视频服务',
          showNoBorder: true,
          englishName: 'Video service',
          intro: '视频有着非常强的传播性，声音与动态画面的结合，极富表现力，更好的形象展示，更强的品牌价值体现；HIMO BUSINESS提供全面的视频服务，从策划、创意、剧本、脚本、拍摄、制作、后期、包装到成片一站式完美体验。',
          link: 'https://b.haimati.cn/#/videoProduct?id=video'
        }
      ],
      showModal: false,
      BUSINESS_FORM
    }
  },
  mounted () {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
  },
  methods: {
    async handleContact () {
      this.showModal = true
    },
    handleClose () {
      this.showModal = false
    }
  }
}
</script>
<style lang="less" scoped>
  .business-cooperation-wrap {
    position: relative;
    background-color: #fff;
    .business-cooperation-content {
      .business-cooperation-head {
        color: #d8d8d8;
        background-image: url("~assets/image/business/business.png");
        background-repeat: no-repeat;
        background-position: center center;
        .title {
          padding-top: 172px;
          font-size: 50px;
          font-family: NexaBold;
        }
        .line {
          span{
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: 12px 8px;
            background-color: #1369b4;
          }
          .black-color {
            background-color: #3f4041;
          }
          .gray-color {
            background-color: #a4a4a4;
          }
        }
        .desc {
          margin: 0 auto;
          padding-bottom: 172px;
        }
      }
      .business-cooperation-container {
        padding-top: 80px;
        .product {
          img {
            padding: 40px 0 60px;
            width: 772px;
          }

          .business-product-intro {
            width: 1152px;
            margin: 0 auto;

            .product-content {
              width: 50%;
              display: inline-block;

              .product-title {
                padding-top: 34px;
                text-align: left;
                .product-num {
                  display: inline-block;
                  padding-right: 14px;
                  color: #e3e3e6;
                  font-size: 60px;
                  font-family: AkrobaFont;
                }

                .product-name {
                  display: inline-block;
                  color: #16161a;
                  font-size: 22px;
                  font-weight: 600;

                  .english-name {
                    color: #d4d4d9;
                    font-size: 14px;
                    font-family: IBMPlexSansBold;
                    font-weight: 400;
                    font-style: oblique;
                  }
                }
              }

              .product-desc {
                padding: 24px 0 36px;
                color: #45454d;
                text-align: left;
                font-size: 12px;
                border-bottom: 1px solid #ebebeb;

                p {
                  padding-bottom: 16px;
                }

                a {
                  color: #000;
                  text-decoration: underline;
                }
              }

              .show-border {
                border-bottom: none;
                padding-bottom: 64px;
              }
            }

            .product-content:nth-child(odd) {
              padding-right: 86px;
            }

            .product-content:nth-child(even) {
              padding-left: 86px;
            }
          }
        }
        .contact-info {
          background-color: #fafafa;
          padding-top: 70px;
          .contact-content{
            position: relative;
            width: 1152px;
            margin: 0 auto;
            padding-bottom: 90px;
            text-align: left;
            .content-title {
              color: #16161a;
              font-size: 24px;
              font-weight: 600;
            }
            .circle{
              padding: 8px 0 15px;
              span {
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #000;
              }
              span:last-child{
                margin-left: 4px;
                background-color: #48ce55;
              }
            }
            .tip {
              color: #45454d;
              font-size: 14px;
            }
            .contact-method {
              display: inline-block;
              padding: 54px 34px 0 0;
              color: #333;
              font-size: 14px;
              font-weight: 600;
              font-family: SFCompactTextSemibold;
              img,p{
                display: inline-block;
                vertical-align: middle;
              }
              img {
                margin-right: 16px;
                width: 24px;
              }
            }
            .line-type {
              margin-top: 64px;
              width: 110px;
            }

            .no-inline {
              display: block;
              padding-top: 50px;
            }
          }
          .code {
            position: absolute;
            top: -20px;
            right: 0;
            color: #45454d;
            font-size: 18px;
            font-family: AkrobaFont;
            font-weight: 800;
            text-align: center;
            img{
              width: 316px;
              height: 316px;
            }
            p {
              padding-top: 20px;
            }
          }
        }
        .partner-info {
          padding-top: 100px;
          margin: 0 auto;
          width: 1152px;
          .scorll-img {
            width: 1152px;
            height: 256px;
            overflow: hidden;
            .img-show {
              width: 200%;
              height: 100%;
              animation: picmove 15s linear infinite forwards;
              img {
                display: inline-block;
                padding: 37px 0 40px;
                width: 1152px;
                height: 256px;
              }
              @keyframes picmove {
                from {
                  transform: translateX(0);
                }
                to {
                  transform: translateX(-1152px);
                }
              }
              &:hover {
                animation-play-state: paused;
              }
            }
          }
        }
      }
    }
    .cooperation-pop {
      display: none;
      position: sticky;
      left: 50%;
      bottom: 10%;
      transform: translateX(-50%);
      width: 375px;
      height: 800px;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      &.isShow {
        display: block;
      }
      .close {
        position: absolute;
        top: 16px;
        right: 15px;
        width: 25px;
      }
      .tit-wrap {
        position: relative;
        width: 100%;
        height: 56px;
        padding: 0 24px;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: block;
          height: 1px;
          box-shadow: 0px 0px 1px 0px #dddddd inset;
        }
        .txt {
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
</style>
