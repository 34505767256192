<template>
  <div class="development-path-wrap">
    <div class="development-path-content">
      <div class="development-path-title">
        <p>海马体发展历程</p>
        <span>高速发展，把美带向生活每一处细节</span>
      </div>
      <div class="development-path-intro">
        <ul>
          <li v-for="(item, index) in timeLineList" :key="index" v-lazy-animate="index * 0.1">
            <div class="content">
              <p class="title">{{ item.title }}</p>
              <p class="tip" v-show="item.tip">{{ item.tip }}</p>
              <p class="time">{{ item.time }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'developmentPath',
  data () {
    return {
      timeLineList: [
        {
          title: '9月 海马体与芭比联名合作推出芭比合作系列产品',
          time: '2023年'
        },
        {
          title: '缦图摄影·北京坊开业',
          tip: '让缘起西湖的婚纱爱情，也在首都落地生花',
          time: '2022年10月'
        },
        {
          title: '海马体与迪士尼联名合作推出迪士尼公主系列产品',
          tip: '展现不被定义的“公主力”',
          time: '2022年11月'
        },
        {
          title: '缦图摄影·北京坊开业',
          tip: '让缘起西湖的婚纱爱情，也在首都落地生花',
          time: '2022年10月'
        },
        {
          title: '海马体联名哈利波特推出全球首款哈利波特正版魔法写真',
          time: '2022年8月'
        },
        {
          title: '百年摄影品牌天胜照相馆加入海马体',
          tip: '看见岁月中摄影的力量',
          time: '2022年2月'
        },
        {
          title: '首家海马体照相馆｜家庭登陆长沙荟聚中心',
          time: '2021年7月'
        },
        {
          title: '“圣诞照 ”创下单品破亿佳绩，再掀行业“圣诞新风尚”',
          time: '2020年12月'
        },
        {
          title: '荣获“2020中国年度最佳雇主-最具发展潜力雇主奖”',
          time: '2020年11月'
        },
        {
          title: '与VERA WANG 联名合作',
          tip: '联名项目-轻婚纱荣获“2020杰出品牌营销奖”',
          time: '2020年10月'
        },
        {
          title: '面对疫情，践行企业使命与责任',
          tip: '累计捐赠150万元，前往武汉为306位医生无偿拍摄，持续为湖北191位医护人员免费拍摄全家福',
          time: '2020年2月'
        },
        {
          title: '首家 shop lab 零售店入驻上海万象城',
          tip: '开启美学周边产品服务',
          time: '2019年12月'
        },
        {
          title: 'WINDCCI正式推出首套自设计彩妆产品',
          tip: '品牌发展进入新阶段',
          time: '2019年11月'
        },
        {
          title: '北京首家大师店诞生',
          tip: '北京华贸中心大师店开业，全国首个重奢项目进驻',
          time: '2019年1月'
        },
        {
          title: ' 第一家泛美学摄影空间——西湖·缦图开业',
          tip: '时尚摄影师陈漫亲临开业现场并点赞缦图作品',
          time: '2018年9月'
        },
        {
          title: '品牌公益IP“幸福照相馆”首次落地',
          tip: ' 免费为上万春运旅客用影像的形式记录回家的喜悦，获众多媒体争相报道',
          time: '2018年2月'
        },
        {
          title: '海马体开始为企业提供商业拍摄，即形象定制服务',
          tip: ' HIMO BUSINESS正式成立',
          time: '2017年7月'
        },
        {
          title: '儿童摄影品牌——海马体照相馆|儿童在杭州滨江宝龙城开业',
          tip: '为0-12 岁小朋友提供专业摄影服务',
          time: '2016年12月'
        },
        {
          title: '海马体照相馆第一家金标店在武汉汉街开业',
          tip: '以艺术化店铺形象设计与产品架构，打造新型城市艺术美学空间',
          time: '2016年11月'
        },
        {
          title: '海马体开启全国连锁经营模式',
          tip: '海马体照相馆哈尔滨店开业',
          time: '2016年3月'
        },
        {
          title: '海马体首创云图像处理+云存储的模式',
          tip: '缦图云端成立，让科技结合美学、科技结合摄影',
          time: '2015年11月'
        },
        {
          title: '成为全国第一家入驻购物中心的照相馆',
          tip: '海马体湖滨银泰in77店开业',
          time: '2015年10月'
        },
        {
          title: '海马体首创“线上预约+线下体验”模式',
          tip: '开发互联网预约系统，上线预约平台',
          time: '2015年4月'
        },
        {
          title: '创立“海马体”，进军证件照这一细分市场',
          tip: '缦图摄影转型快时尚摄影，主打闺蜜写真。',
          time: '2014年'
        },
        {
          title: '缦图摄影正式成立',
          tip: '创立之初品牌命名为“曼图摄影”，意为曼妙的图片',
          time: '2011年11月'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.development-path-wrap {
  background-color: #fff;
  position: relative;
  .development-path-content {
    margin: 100px auto 0;
    .development-path-title {
      color: #16161a;
      p {
        font-size: 46px;
        font-weight: 600;
      }
      span {
        display: block;
        padding-top: 10px;
        font-size: 16px;
        font-weight: 300;
      }
    }
    .development-path-intro {
      margin-top: 85px;
      ul {
        background: #fff;
        padding: 50px 0;

        li {
          background: #e3e3e6;
          position: relative;
          margin: 0 auto;
          width: 1px;
          min-height: 150px;
          padding-bottom: 40px;
          list-style-type: none;

          &:last-child {
            padding-bottom: 7px;
          }

          &:before {
            content: "";
            background: #48ce55;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            width: 13px;
            height: 13px;
            border-radius: 50%;
          }

          &:nth-child(even):before {
            background: #000;
          }

          .hidden {
            opacity: 0;
          }

          .content {
            position: relative;
            top: -30px;
            width: 600px;

            p {
              margin: 0;
              padding: 0;
              text-align: left;
            }

            .title {
              color: #45454d;
              font-size: 24px;
              font-weight: 600;
            }

            .tip {
              padding: 4px 0 20px;
              color: #45454d;
              font-size: 14px;
              font-weight: 300;
            }

            .time {
              color: #919199;
              font-size: 16px;
              font-weight: 300;
            }
          }

          &:nth-child(odd) .content {
            left: 50px;
          }

          &:nth-child(even) .content {
            left: calc(-600px - 45px);
            p {
              text-align: right;
            }
          }
        }
        li:last-child {
          width: 0;
        }
      }
    }
  }
}
</style>
