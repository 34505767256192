<template>
  <div class="new-product-wrap" v-lazy-animate="0.2">
    <LineWrap :titleObj="titleObj"></LineWrap>
    <ProductCarousel></ProductCarousel>
  </div>
</template>
<script>
import LineWrap from '../../components/LineWrap'
import ProductCarousel from './component/ProductCarousel'
export default {
  name: 'newProduct',
  components: {
    LineWrap,
    ProductCarousel
  },
  data () {
    return {
      titleObj: {
        title: '最新产品',
        tip: '关注动态 改变自己'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.new-product-wrap {
  padding: 90px 0 20px;
}
</style>
