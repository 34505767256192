import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/reset.less'
import '@/assets/style/font.less'
import 'swiper/css/swiper.css'
import echarts from 'echarts'
import axios from 'axios'
import { LazyAnimate } from '@/utils/lazyAnimate'
import VueAMap from 'vue-amap'
Vue.use(VueAMap)

// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '07d827e0c2196178812818e75110b5af',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
})
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios

router.afterEach((to, from) => {
  window.scrollTo(0, 0)
})

let lazy = new LazyAnimate()

Vue.directive('lazy-animate', {
  bind: (el, args) => {
    lazy.bind(el, args.value)
  },
  inserted: (el, args) => {
    lazy.join(el, args.value)
  },
  unbind: el => {
    lazy.remove(el)
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
