<template>
  <div class="line-wrap">
    <div class="line" :class="showCircle ? 'line-bottom' : ''"></div>
    <div class="line-content" :class="showCircle ? 'font-change' : ''">
      <p class="title">{{ titleObj.title }}</p>
      <p>{{ titleObj.tip }}</p>
      <div class="circle">
        <p></p>
        <p></p>
      </div>
    </div>
    <div class="line-right" :class="showCircle ? 'line-bottom' : ''"></div>
  </div>
</template>
<script>
export default {
  name: 'line-wrap',
  props: {
    titleObj: Object,
    showCircle: Boolean
  }
}
</script>
<style lang="less" scoped>
.line-wrap {
  width: 1152px;
  margin: 0 auto;
  position: relative;
  .line {
    position: absolute;
    top: 45px;
    left: 0;
    width: 384px;
    height: 1px;
    background-color: #ebebeb;
    display: inline-block;
  }
  .line-right {
    position: absolute;
    top: 45px;
    right: 0;
    width: 384px;
    height: 1px;
    background-color: #ebebeb;
    display: inline-block;
  }
  .line-bottom {
    position: absolute;
    bottom: 14px;
    top: unset;
    width: 43.4%;
  }
  .line-content {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 89px 0;

    .title {
      padding-bottom: 4px;
      color: #16161a;
      font-size: 24px;
      font-weight: 600;
    }
    p {
      padding-bottom: 3px;
      color: #919199;
      font-size: 14px;
    }

    .circle {
      padding-top: 4px;
      p {
        width: 16px;
        height: 16px;
        background-color: #48ce55;
        border-radius: 50%;
        display: inline-block;
      }
      p:last-child {
        background-color: #000;
        margin-left: 4px;
      }
    }
  }
  .font-change {
    .title {
      font-size: 40px;
      font-weight: 600;
      padding-bottom: 14px;
    }
    .circle {
      margin-top: 26px;
    }
  }
}
</style>
