<template>
  <div class="store-info">
    <div class="city-show">
      <div class="echarts" ref="myEchart"></div>
      <div class="left-img">
        <img src="~/assets/image/icon/himo-logo.png" />
        <p>全国{{ cityLength }}城 · {{ storeLength }}家门店</p>
      </div>
      <div class="right-img">
        <img src="~/assets/image/icon/blue-logo.png" />
        <img src="~/assets/image/icon/gold-logo.png" />
        <img src="~/assets/image/icon/family-logo.png" />
      </div>
    </div>
    <div class="city-search">
      <div class="entry-city">
        <p>城市选择</p>
        <input type="text" placeholder="请输入" v-model="enterCity" />
        <div class="search-btn" @click="searchCity">
          <span>搜索</span>
        </div>
      </div>
      <div class="hot-city">
        <p>热门城市</p>
        <ul>
          <li
            v-for="(item, index) in hotCityList"
            :key="index"
            @click="changeCity(item.id, index)"
            :class="index === curIndex ? 'active-city' : ''"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="curCityStore.length < 1" class="city-tip">当前城市暂无门店</div>
    <div class="store-list">
      <div class="store-item" v-for="(item, index) in curCityStore" :key="index">
        <div class="item-left">
          <p
            class="name"
            :class="item.storeType === 'gold' ? 'gold-name' : ''"
          >
            {{ item.name }}
          </p>
          <img class="logo" src="~/assets/image/logo/himo-blue.png" v-if="item.storeType === 'blue'" />
          <img class="logo" src="~/assets/image/logo/himo-gold.png" v-else-if="item.storeType === 'gold'" />
          <img class="logo" src="~/assets/image/logo/himo-family.png" v-else-if="item.storeType === 'family'" />
          <p class="address">门店地址: <span class="address-detail">{{ item.address }}</span></p>
          <p class="phone">门店客服：<span>{{ item.phone }}</span></p>
          <p class="time">营业时间：<span>{{ item.businessTime }}</span></p>
          <p
            @click="item.showMap = !item.showMap"
            class="show-btn"
            :class="item.storeType === 'gold' ? 'gold-map' : ''"
          >
            {{ item.showMap ? '查看门店照片' : '查看地图导航' }}>
          </p>
        </div>
        <div class="item-right">
          <MapWrap :location="item.location" :id="index" v-if="item.showMap"></MapWrap>
          <img :src="item.photo" v-else />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import echarts from 'echarts'
import axios from 'axios'
import 'echarts/map/js/china.js' // 引入中国地图数据
import MapWrap from '../../components/Map'
import _ from 'lodash'

export default {
  name: 'echarts',
  components: {
    MapWrap
  },
  data () {
    return {
      chart: null,
      data: [],
      map: {},
      hotCityList: [
        {
          name: '北京',
          id: 110000000
        },
        {
          name: '武汉',
          id: 420100000
        },
        {
          name: '成都',
          id: 510100000
        },
        {
          name: '上海',
          id: 310000000
        },
        {
          name: '重庆',
          id: 500000000
        },
        {
          name: '南京',
          id: 320100000
        },
        {
          name: '杭州',
          id: 330100000
        },
        {
          name: '深圳',
          id: 440300000
        }
      ],
      domain: process.env.VUE_APP_API_HOST,
      cityList: [],
      selectCity: '',
      hotCity: '',
      curIndex: 6, // 菜单的下标
      cityLength: null,
      storeLength: null,
      enterCity: '', // 城市选择输入框的值
      showMap: false,
      showIndex: null,
      curCityStore: [],
      myChart: null,
      chartOption: null
    }
  },
  watch: {
    selectCity () {
      this.getCityList()
    }
  },
  mounted () {
    this.getStoreInfo()
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    this.myChart.off('mouseover')
    this.myChart.off('mouseout')
  },
  methods: {
    // 获取选中的城市的门店列表
    getCityList () {
      if (this.selectCity) {
        this.curCityStore = this.cityList[this.selectCity].storeInfo
        this.showState = true
      }
    },
    // 判断2点是否重叠或相近
    ifOverlap (x1, y1, x2, y2) {
      if (x1 + 0.4 > x2 && x2 + 0.4 > x1 && y1 + 0.5 > y2 && y2 + 0.5 > y1) {
        return true
      } else {
        return false
      }
    },
    // 递归处理经纬度数据
    processData (res, a) {
      let len = res.length
      let overlap = false
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < len; j++) {
          if (i === j) continue
          let flag = this.ifOverlap(res[i].value[0], res[i].value[1], res[j].value[0], res[j].value[1])
          if (flag) {
            overlap = true
            res[i].value[0] += a
            res[i].value[1] += a
          }
        }
      }
      a = -a
      if (!overlap) {
        return res
      }
      return this.processData(res, a)
    },
    // 地图所需数据处理
    convertData () {
      const data = this.data.sort(function (a, b) {
        return b.value - a.value
      })
      const len = data.length
      let res = []
      for (let i = 0; i < len; i++) {
        if (!data[i]) continue
        let geoCoord = this.map[data[i].name]
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
            type: data[i].type
          })
        }
      }
      this.processData(res, 0.8)
      this.data = res
    },

    // 地图相关配置
    chinaConfigure () {
      this.myChart = echarts.init(this.$refs.myEchart) // 这里是为了获得容器所在位置
      window.onresize = this.myChart.resize

      this.chartOption = {
        // 进行相关配置
        backgroundColor: '#fff',
        geo: {
          // 这个是重点配置区
          map: 'china', // 表示中国地图
          roam: false,
          label: {
            normal: {
              show: true, // 是否显示对应地名
              textStyle: {
                color: '#f5f5f7'
              }
            }
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 0.2)',
              borderType: 'dashed',
              areaColor: '#f5f5f7'
            },
            emphasis: {
              areaColor: '#0b1c2d'
            }
          },
          silent: true
        },
        series: [
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: this.data
              .sort(function (a, b) {
                return b.value - a.value
              })
              .slice(0, 10)
              .filter(item => {
                return item.type === 'gold'
              }),
            symbolSize: function (val) {
              return Math.min(val[2] * 2.5 + 5, 4)
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke',
              scale: 6,
              period: 6
            },
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                offset: [-4, -10],
                width: 100,
                backgroundColor: '#fff',
                padding: [2, 4],
                borderRadius: 4,
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#c5a563',
                shadowBlur: 50,
                shadowColor: '#c5a563'
              }
            },
            zlevel: 2
          },
          {
            name: 'effectScatter-blue',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: this.data
              .sort(function (a, b) {
                return b.value - a.value
              })
              .slice(0, 10)
              .filter(item => {
                return item.type === 'blue'
              }),
            symbolSize: function (val) {
              return Math.min(val[2] * 2.5 + 5, 4)
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke',
              scale: 6,
              period: 6
            },
            label: {
              normal: {
                formatter: '{b}',
                position: 'left',
                offset: [2, 6],
                backgroundColor: '#fff',
                padding: [2, 4],
                borderRadius: 4,
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#000000',
                shadowBlur: 10,
                shadowColor: '#000000'
              }
            },
            zlevel: 2
          },
          {
            name: 'scatter-blue',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: this.data
              .sort(function (a, b) {
                return b.value - a.value
              })
              .slice(10)
              .filter(item => {
                return item.type === 'blue'
              }),
            symbolSize: function (val) {
              return Math.min(val[2] * 2.5 + 2, 6)
            },
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              emphasis: {
                backgroundColor: '#fff',
                padding: [2, 4],
                borderRadius: 4,
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#000000'
              }
            }
          },
          {
            name: 'scatter-gold',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: this.data
              .sort(function (a, b) {
                return b.value - a.value
              })
              .slice(10)
              .filter(item => {
                return item.type === 'gold'
              }),
            symbolSize: function (val) {
              return Math.min(val[2] * 2, 6)
            },
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              emphasis: {
                backgroundColor: '#fff',
                padding: [2, 4],
                borderRadius: 4,
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#c5a563'
              }
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            }
          },
          {
            name: 'scatter-family',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: this.data
              .sort(function (a, b) {
                return b.value - a.value
              })
              .slice(2)
              .filter(item => {
                return item.type === 'family'
              }),
            symbolSize: function (val) {
              return Math.min(val[2] * 2.5 + 4, 6)
            },
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              emphasis: {
                backgroundColor: '#fff',
                padding: [2, 4],
                borderRadius: 4,
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#e06c00'
              }
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            }
          }
        ]
      }

      this.myChart.setOption(this.chartOption)
      this.changeChart('mouseover', 'scatter-blue')
      this.changeChart('mouseover', 'scatter-gold')
      this.changeChart('mouseout', 'scatter-blue')
      this.changeChart('mouseout', 'scatter-gold')
      this.changeChart('mouseout', 'scatter-family')
    },

    // 地图鼠标事件
    changeChart (event, name) {
      if (event === 'mouseover') {
        this.myChart.on('mouseover', { seriesName: name }, () => {
          this.chartOption.series[1].label.normal.show = false
          this.chartOption.series[0].label.normal.show = false
          this.myChart.setOption(this.chartOption)
        })
      } else if (event === 'mouseout') {
        this.myChart.on('mouseout', { seriesName: name }, () => {
          this.chartOption.series[1].label.normal.show = true
          this.chartOption.series[0].label.normal.show = true
          setTimeout(() => {
            this.myChart.setOption(this.chartOption)
          }, 1000)
        })
      }
    },


    // 获取门店信息
    async getStoreInfo () {
      const data = { store_type: ['blue', 'gold', 'family'] }
      let stores = await axios.post(`${this.domain}/store/getStoreByCity`, {
        ...data,
        limit: 1000,
        type: 'baseExtend'
      })
      this.storeLength = stores.data.msg.length
      let citys = await axios.post(`${this.domain}/store/getHaveStoreCity`, data)
      citys = _.keyBy(citys.data.msg, 'id')
      _(stores.data.msg)
        .groupBy('area_id')
        .each((item, area) => {
          citys[area] = Object.assign(citys[area], {
            count: item.length,
            storeInfo: _.map(item, (store) => {
              return {
                name: store.name,
                location: store.extend.location.split(','),
                address: store.extend.address,
                photo: store.extend.photo,
                phone: store.extend.phone,
                storeType: store.store_type,
                businessTime: store.extend.business_hours ? store.extend.business_hours : (store.extend.business_time && store.extend.business_time.start_at && store.extend.business_time.start_at.length !== 0 ? Object.values(store.extend.business_time).reverse().join('--') : '暂无信息'),
                showMap: false
              }
            }),
            location: item[0].extend.location.split(',')
          })
        })
      _(citys).each(item => {
        let type = 'blue'
        if (item.store_type.indexOf('family') > -1) {
          type = 'family'
        } else if (item.store_type.indexOf('gold') > -1) {
          type = 'gold'
        }
        this.data.push({
          name: item.name,
          value: item.count,
          type: type
        })
        this.map[item.name] = _.map(item.location, i => parseFloat(i).toFixed(2))
      })
      this.convertData()
      this.cityList = citys
      this.cityLength = this.data.length
      if (this.$route.query.id) {
        this.selectCity = this.$route.query.id
        _.map(this.hotCityList, (item, i) => {
          if (item.id === parseInt(this.$route.query.id)) {
            this.curIndex = i
          } else return {}
        })
      } else {
        this.selectCity = 330100000
      }
      this.chinaConfigure()
    },
    /**
     * [changeCity 点击改变选中的城市]
     * @param  {[Number]} id [城市id]
     * @param  {[Number]} index [城市菜单下标]
     */
    changeCity (id, index) {
      this.enterCity = null
      this.selectCity = id
      this.curIndex = index
    },
    cancelCity () {
      this.curIndex = null
    },
    lookMap (index) {
      this.showIndex = index
      this.showMap = !this.showMap
    },
    // 搜索城市信息
    searchCity () {
      if (this.enterCity) {
        this.curIndex = null
        this.selectCity = null
        this.curCityStore = []
        _.map(this.cityList, item => {
          if (_.includes(item.name, this.enterCity)) {
            this.selectCity = item.id
            this.getCityList()
            return false
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .store-info {
    .city-show {
      position: relative;
      width: 1152px;
      margin: 0 auto;
      .echarts {
        width: 100%;
        height: 735px;
      }
      .left-img {
        position: absolute;
        top: 71px;
        left: 94px;
        text-align: left;
        img {
          display: block;
          width: 248px;
        }
        p {
          margin-left: -4px;
          padding: 4px 4px 0 0;
          display: inline-block;
          background-image: linear-gradient(135deg,#131313,#525252);
          -webkit-background-clip:text;
          color: transparent;
          font-size: 25px;
          font-weight: 600;
          font-style: oblique;
          vertical-align: middle;
        }
      }
      .right-img {
        position: absolute;
        top: 360px;
        right: 100px;
        img {
          display: block;
          width: 109px;
        }
      }
    }
    .city-search {
      width: 1152px;
      margin: 0 auto;
      padding-bottom: 40px;
      text-align: left;
      .hot-city {
        display: inline-block;
        vertical-align: middle;

        p {
          color: #000;
          font-size: 14px;
          font-weight: 500;
        }
        .active-city {
          color: #48ce55;
          font-weight: 500;
          transition: all 1s;

          &::after {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            margin-top: -4px;
            background-color: #48ce55;
          }
        }
        p,
        ul {
          display: inline-block;
        }
        li {
          display: inline-block;
          cursor: pointer;
          color: #45454d;
          font-size: 14px;
          padding: 0 15px;

          &:hover{
            color: #48ce55;
          }
        }
        li:first-child {
          padding-left: 20px;
        }
      }
      .choose-city {
        padding: 20px 0;
        display: inline-block;
        .title {
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          color: #45454d;
          padding-right: 20px;
        }
        .province-select {
          position: relative;
          display: inline-block;
          width: 122px;
          height: 36px;
          line-height: 36px;
          vertical-align: middle;
          padding: 0;
          overflow: hidden;
          text-shadow: none;
          border: 1px solid #e3e3e6;

          select {
            display: inline-block;
            cursor: pointer;
            padding-left: 10px;
            width: 100%;
            color: #919199;
            opacity: 1;
            background-color: transparent;
            font-size: 12px;
            border: none;
            background-image: none;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            vertical-align: text-top;

            &:focus {
              outline: 0;
            }
          }

          &:hover {
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
          }
          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border-top: 1px solid #979797;
            border-right: 1px solid #979797;
            transform: rotate(135deg);
            top: 11px;
            right: 12px;
            cursor: pointer;
            z-index: -2;
          }
        }
      }
      .entry-city {
        display: inline-block;
        vertical-align: middle;
        padding-right: 40px;
        overflow: hidden;

        p {
          display: inline-block;
          padding-right: 20px;
          color: #45454d;
          font-size: 14px;
          font-weight: 500;
        }
        input {
          width: 140px;
          height: 36px;
          line-height: 36px;
          padding: 10px;
          outline: none;
          border: 1px solid #e3e3e6;

          &::-webkit-input-placeholder {
            color: #979797;
          }
        }
        .search-btn {
          display: inline-block;
          width: 56px;
          height: 36px;
          line-height: 36px;
          background-color: #f2f2f5;
          color: #45454d;
          font-size: 12px;
          font-weight: 400;
          border: 1px solid #e3e3e6;
          border-left: none;
          vertical-align: bottom;
          text-align: center;
          cursor: pointer;

          &:hover {
            background-color: #dedee0;
            transition: all .8s;
          }
        }
      }
    }
    .store-list {
      width: 1152px;
      margin: 0 auto 30px;

      .store-item {
        background-color: #fafafa;
        margin: 10px 0;
        max-height: 314px;
        .item-left {
          width: 466px;
          padding: 30px 58px 42px 40px;
          text-align: left;
          .name {
            display: inline-block;
            max-width: 300px;
            font-size: 24px;
            font-weight: 600;
            color: #45454d;
            vertical-align: bottom;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .gold-name {
            color: #8a8173;
          }
          .logo {
            display: inline-block;
            margin-left: 14px;
            width: 22px;
            height: 22px;
          }
          .address {
            padding: 25px 0 30px;
            font-size: 14px;
            color: #45454d;
            .address-detail {
              display: block;
              color: #919199;
            }
          }
          .phone {
            color: #45454d;
            font-size: 14px;
            span{
              color: #919199;
            }
          }
          .time {
            padding: 10px 0 30px;
            color: #45454d;
            font-size: 14px;
            span{
              color: #919199;
            }
          }
          .show-btn {
            color: #000;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
          }
          .gold-map {
            color: #8a8173;
          }
        }
        .item-left,.item-right{
          display: inline-block;
          vertical-align: top;
          img {
            width: 686px;
          }
        }
        .item-right {
          width: 686px;
          height: 315px;
          overflow: hidden;
        }
      }
    }
    .city-tip {
      padding-bottom: 40px;
      transition: opacity 2.8s ease;
      &.show {
        opacity: 1;
      }
    }
  }
</style>
