<template>
  <div class="brand-line-wrap">
    <div
      class="brand-content"
      v-lazy-animate="index * 0.2"
      v-for="(item, index) in brandInfo"
      :style="!item.img ? 'padding:0' : ''"
      :key="index"
      :class="item.showClass ? 'gray-bg' : ''"
    >
      <div class="content-set">
        <div
          class="img-content"
          :class="item.showClass ? 'content-right' : ''"
          :style="centerShow ? 'width:100%;text-align:center;margin:0' : ''"
          :id="`anchor-bar-${index}`"
        >
          <p class="title">{{ item.title }}</p>
          <p class="tip">{{ item.tip }}</p>
          <div class="circle">
            <p></p>
            <p></p>
          </div>
          <p class="intro" v-html="item.intro"></p>
        </div>
        <div class="img" :class="item.showBg ? 'bg-img' : ''" v-show="item.img">
          <img :src="item.img" alt />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BrandLine',
  props: {
    brandInfo: Array,
    centerShow: Boolean
  }
}
</script>
<style lang="less" scoped>
.brand-line-wrap {
  height: auto;
  .brand-content {
    padding: 120px 0;
    .content-set {
      width: 1152px;
      margin: 0 auto;
      .img-content {
        width: 463px;
        margin-right: 113px;
        display: inline-block;
        text-align: left;
        vertical-align: top;

        .title {
          font-size: 42px;
          font-weight: 600;
          color: #16161a;
        }

        .tip {
          font-size: 16px;
          color: #16161a;
        }

        .circle {
          padding: 14px 0 48px 0;

          p {
            width: 16px;
            height: 16px;
            background-color: #000;
            border-radius: 50%;
            display: inline-block;
          }

          p:last-child {
            background-color: #48ce55;
            margin-left: 4px;
          }
        }

        .intro {
          width: 100%;
          background-image: linear-gradient(
            to right,
            #d4d4d9 0%,
            #d4d4d9 50%,
            transparent 50%
          );
          background-size: 10px 1px;
          background-repeat: repeat-x;
          padding-top: 15px;
          font-size: 16px;
          color: #45454d;
          line-height: 40px;
        }
      }

      .content-right {
        float: right;
        margin-right: 0;
        margin-left: 113px;
      }

      .img {
        position: relative;
        display: inline-block;
        width: 538px;
        height: 419px;
        background-color: #f2f2f5;

        img {
          position: absolute;
          width: 100%;
          bottom: 15px;
          right: 15px;
        }
      }
      .bg-img {
        background-color: transparent;
        width: 484px;
        height: 484px;
        img {
          width: 484px;
        }
      }
    }
  }
  .gray-bg {
    background-color: #fafafa;
  }
}
.brand-line-center {
  margin: 0 auto;
  text-align: center;
}
</style>
