<template>
  <div class="tab-wrap">
    <ul>
      <li
        v-for="(item, index) in navList"
        :key="index"
        @click="addClass(index)"
        :class="{ active: currentIndex === index }"
        v-lazy-animate
      >
        <p :href="`#section-bar-${index}`">{{ item }}</p>
      </li>
    </ul>
    <div class="tab-content">
      <section
        v-for="(item, index) in imgList"
        :key="index"
        :id="`section-bar-${index}`"
        class="tab-section"
        :class="{active:currentIndex === index}"
      >
        <template v-if="currentIndex === index">
          <div
            class="server-content"
            v-for="(serveItem, serveIndex) in item.serveInfo"
            :key="serveIndex"
            v-lazy-animate="serveIndex * 0.2"
          >
            <img :src="serveItem.img" />
            <p class="server-name">{{ serveItem.name }}</p>
            <p class="font-style">{{ serveItem.englishName }}</p>
            <span v-html="serveItem.intro"></span>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tab-wrap',
  data () {
    return {
      navList: ['海马体标准服务', '大师店标准服务'],
      imgList: [
        {
          serveInfo: [
            {
              name: '拍摄服务',
              intro:
                `ꔷ 配备私密影棚，让您在拍摄时尽享舒适空间服务 </br>ꔷ 使用尼康、佳能等专业人像摄影器材，绝佳的镜头解析力为你展现最美肤色质感 `,
              img: require('assets/image/buleServe/photography.jpg')
            },
            {
              name: '造型服务',
              intro:
                `ꔷ 免费提供亲肤化妆用品，全部选用专业彩妆品牌，打造精致妆容 </br>ꔷ 造型师通过专业化的形象设计，为您量身打造符合个人气质的个性妆容和服装搭配`,
              img: require('assets/image/buleServe/makeup.jpg')
            },
            {
              name: '服装服务',
              intro:
                `ꔷ 根据具体拍摄场景，搭配不同风格的服装及配饰，提供形象设计相关的全套解决方案 </br>ꔷ 服装定期送洗消毒，给您放心体验`,
              img: require('assets/image/buleServe/clothing.png')
            },
            {
              name: '图像处理',
              intro:
                `ꔷ 由行业领先的“缦图云端”图像处理中心提供高标准高效率的修片支持 </br>ꔷ 全系采用iMac看片、修图，呈现最佳色彩体验`,
              img: require('assets/image/buleServe/ps.png')
            },
            {
              name: '图像输出',
              intro:
                `ꔷ 门店使用DNP专业数码影像打印机 </br>ꔷ 使用原装进口的高品质防水耐刮擦相纸，保护相片经历时间考验 </br>ꔷ 运用专业色彩管理系统，还原肉眼可见真实色彩`,
              img: require('assets/image/buleServe/print.png')
            }
          ]
        },
        {
          serveInfo: [
            {
              name: '美学空间',
              intro: `ꔷ 门店采用一店一设计概念，以城市特色为元素，将城市生活方式融入其中，诠释一个新型城市美学空间</br>ꔷ 爱情主题店等各具特色的主题门店设计和配套设施，给您最沉浸的拍摄体验和最极致的仪式感`,
              img: require('assets/image/glodServe/marshalling.jpg')
            },
            {
              name: '造型服务',
              intro:
                `ꔷ 店内化妆品均选用迪奥、TF、YSL等一线大牌，塑造高级妆容</br>ꔷ 造型师通过专业化的形象设计，为您量身打造符合个人气质的个性妆容和服装搭配`,
              img: require('assets/image/glodServe/staff.jpg')
            },
            {
              name: '拍摄服务',
              intro:
                `ꔷ 相机使用CANON的5D3/4+24-70的组合，绝佳的镜头解析力为你展现最美肤色质感（具体产品应用设备以门店实际情况为准）`,
              img: require('assets/image/glodServe/clothing.jpg')
            },
            {
              name: '图像处理',
              intro:
                `ꔷ 由行业领先的“缦图云端”图像处理中心提供高标准高效率的修片支持</br>ꔷ 全系采用iMac看片、修图，呈现最佳色彩体验</br>ꔷ 我们将根据您的个性化需求，提供专业建议及线上1v1修片服务`,
              img: require('assets/image/glodServe/space.jpg')
            },
            {
              name: '图像输出',
              intro:
                `ꔷ 门店使用DNP专业数码影像打印机</br>ꔷ 使用原装进口的高品质防水耐刮擦相纸，保护相片经历时间考验</br>ꔷ 运用专业色彩管理系统，还原肉眼可见真实色彩`,
              img: require('assets/image/glodServe/makeup.jpg')
            }
          ]
        }
      ],
      currentIndex: 0
    }
  },
  methods: {
    addClass (index) {
      this.currentIndex = index
    }
  }
}
</script>
<style lang="less" scoped>
.tab-wrap {
  margin-top: 34px;
  ul {
    li {
      display: inline-block;
      padding: 16px 90px;
      border: 1px solid #d4d4d9;
      border-radius: 2px 0 0 2px;
      font-size: 14px;
      color: #45454d;

      &:last-child {
        border-radius: 0 2px 2px 0;
      }
      &:hover {
        border: 1px solid #000;
        background-color: #000;
        color: #fff;
        transition: all 1s;
      }
      &:hover:last-child {
        border: 1px solid #c5a563;
        background-color: #c5a563;
        color: #fff;
        transition: all 1s;
      }
      &.active {
        border: 1px solid #000;
        background-color: #000;
        color: #fff;
      }
      &.active:last-child {
        border: 1px solid #c5a563;
        background-color: #c5a563;
        color: #fff;
      }
    }
  }
  .tab-content {
    width: 1152px;
    margin: 60px auto 0;
    .tab-section {
      display: none;
      &.active {
        display: block;
        transition: all 2s;
      }

      img {
        width: 100%;
      }

      .server-content {
        display: inline-block;
        width: 20%;
        height: 384px;
        padding: 0 12px;
        vertical-align: top;
        img {
          width: 211px;
        }
        .server-name {
          font-size: 24px;
          font-weight: 600;
          color: #16161a;
          padding: 20px 0 2px;
        }
        .font-style {
          font-style: oblique;
        }
        p {
          padding-bottom: 14px;
          font-size: 14px;
          font-family: IBMPlexSansBold;
          color: #d4d4d9;
        }
        span {
          display: block;
          width: 206px;
          color: #45454d;
          font-size: 13px;
          text-align: left;
        }
      }
    }
    .tab-section:last-child {
      .server-name {
        color: #c5a563;
      }
      p {
        color: #f0e2c7;
        font-weight: normal;
      }
    }
  }
}
</style>
