<template>
  <div class="product-wrap" v-lazy-animate="0.2">
    <LineWrap :titleObj="titleObj"></LineWrap>
    <div class="product-nav">
      <div class="nav" ref="nav" :class="showScroll ? 'fixed' : ''">
        <ul>
          <li
            v-for="(item, index) in navList"
            :key="index"
            @click="changeIndex(index)"
            :class="index === curIndex ? 'active' : ''"
          >
            <p>{{ item }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="product-margin">
      <div class="product-bg">
        <img src="~/assets/image/newProduct/product-bg.png" alt="" />
      </div>
    </div>
    <section
      class="product-content-wrap"
      v-for="(item, index) in productSeries"
      :key="index"
      :id="`product-bar-${index}`"
      :class="index === curIndex ? 'active' : ''"
      v-lazy-animate="index * 0.2"
    >
      <div class="title" v-show="item.title">
        <span class="line"></span>
        <p>{{ item.title }}</p>
        <span class="line-right"></span>
      </div>
      <div class="product-content">
        <div
          class="content"
          v-for="(productItem, productIndex) in item.productList"
          :key="productIndex"
          :class="productItem.singePhoto ? 'single-content' : ''"
          @click="productDetail(productItem)"
        >
          <img :src="productItem.img" />
          <div class="desc">
            <p>{{ productItem.name }}</p>
            <span>{{ productItem.tip }}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import LineWrap from '../../components/LineWrap'
export default {
  name: 'product',
  components: {
    LineWrap
  },
  data () {
    return {
      curIndex: 0,
      titleObj: {
        title: '全部样片',
        tip: '生活需要仪式感'
      },
      navList: ['个性审美', '情感记录', '证件照', '职场照'],
      productSeries: [
        {
          productList: [
            {
              img: require('assets/image/limitProduct/1_1_1.jpg'),
              singePhoto: true,
              name: '文艺照 彩色',
              tip: '海马体照相馆',
              id: 116631,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_2_1.jpg'),
              singePhoto: true,
              name: '文艺照 黑白',
              tip: '海马体照相馆',
              id: 116631,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_3_1.jpg'),
              singePhoto: true,
              name: '生日照',
              tip: '海马体照相馆',
              id: 123068,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_4_1.jpg'),
              singePhoto: true,
              name: 'The Girl',
              tip: '海马体照相馆',
              id: 102961,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_5_1.jpg'),
              singePhoto: true,
              name: '国风照',
              tip: '海马体照相馆',
              id: 107810,
              storeId: 1190,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_6_1.jpg'),
              singePhoto: true,
              name: '元气限定',
              tip: '海马体照相馆',
              id: 105959,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_7_1.jpg'),
              singePhoto: true,
              name: 'LOLITA',
              tip: '海马体照相馆',
              id: 95072,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_8_1.jpg'),
              singePhoto: true,
              name: '大师肖像',
              tip: '海马体大师',
              id: 93475,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_9_1.jpg'),
              singePhoto: true,
              name: '迪士尼公主系列',
              tip: '海马体照相馆',
              id: 113420,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_10_1.jpg'),
              singePhoto: true,
              name: '花颜照',
              tip: '海马体大师',
              id: 27203,
              storeId: 1326,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_11_1.jpg'),
              singePhoto: true,
              name: '电影胶片',
              tip: '海马体大师',
              id: 107275,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_12_1.jpg'),
              singePhoto: true,
              name: 'Remember',
              tip: '海马体照相馆',
              id: 121641,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_14_1.jpg'),
              singePhoto: true,
              name: '摩登新篇',
              tip: '海马体大师',
              id: 107904,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/1_15_1.jpg'),
              singePhoto: true,
              name: '大师国风',
              tip: '海马体大师',
              id: 102828,
              storeId: 1511,
              type: 'product'
            }
          ]
        },
        {
          title: '情感记录',
          productList: [
            {
              img: require('assets/image/limitProduct/2_1_1.jpg'),
              singePhoto: false,
              name: 'VERA WANG合作款轻婚纱',
              tip: '海马体大师',
              id: 93179,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_2_1.jpg'),
              singePhoto: false,
              name: '结婚登记照',
              tip: '海马体大师',
              id: 25462,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_3_1.jpg'),
              singePhoto: true,
              name: 'First Look',
              tip: '海马体照相馆',
              id: 103441,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_4_1.jpg'),
              singePhoto: true,
              name: '纪念照',
              tip: '海马体照相馆',
              id: 103422,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_5_1.jpg'),
              singePhoto: true,
              name: '小幸孕',
              tip: '海马体照相馆',
              id: 105217,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_6_1.jpg'),
              singePhoto: true,
              name: '亲子照',
              tip: '海马体照相馆',
              id: 107421,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_7_1.jpg'),
              singePhoto: false,
              name: '全家福',
              tip: '海马体照相馆',
              id: 28410,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_8_1.jpg'),
              singePhoto: true,
              name: 'Twins',
              tip: '海马体照相馆',
              id: 120423,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_10_1.jpg'),
              singePhoto: false,
              name: '结婚登记照',
              tip: '海马体照相馆',
              id: 25462,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_11_1.jpg'),
              singePhoto: false,
              name: '结婚纪念照',
              tip: '海马体大师',
              id: 103429,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_12_1.jpg'),
              singePhoto: true,
              name: '轻婚纱-经典',
              tip: '海马体大师',
              id: 28407,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_13_1.jpg'),
              singePhoto: true,
              name: '致爱照',
              tip: '海马体大师',
              id: 28415,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_14_1.jpg'),
              singePhoto: true,
              name: '全家福',
              tip: '海马体大师',
              id: 28408,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/2_15_1.jpg'),
              singePhoto: true,
              name: '孕妇照',
              tip: '海马体大师',
              id: 28434,
              storeId: 1424,
              type: 'product'
            }
          ]
        },
        {
          title: '证件照',
          productList: [
            {
              img: require('assets/image/limitProduct/3_1_1.jpg'),
              singePhoto: true,
              name: '证件照-经典通用',
              tip: '海马体照相馆',
              id: 105412,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/3_2_1.jpg'),
              singePhoto: true,
              name: '证件照-学院风',
              tip: '海马体照相馆',
              id: 105414,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/3_3_1.jpg'),
              singePhoto: true,
              name: '证件照-入园照',
              tip: '海马体照相馆',
              id: 105421,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/3_4_1.jpg'),
              singePhoto: true,
              name: '证件照-经典通用',
              tip: '海马体大师',
              id: 96339,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/3_5_1.jpg'),
              singePhoto: true,
              name: '证件照-经典韩式',
              tip: '海马体大师',
              id: 96340,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/3_6_1.jpg'),
              singePhoto: true,
              name: '证件照-入学照',
              tip: '海马体大师',
              id: 96342,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/3_7_1.jpg'),
              singePhoto: true,
              name: '证件照-出行签证',
              tip: '海马体大师',
              id: 96342,
              storeId: 1424,
              type: 'product'
            }
          ]
        },
        {
          title: '职场照',
          productList: [
            {
              img: require('assets/image/limitProduct/4_1_1.jpg'),
              singePhoto: true,
              name: '形象照',
              tip: '海马体照相馆',
              id: 115869,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/4_2_1.jpg'),
              singePhoto: true,
              name: '商务形象照',
              tip: '海马体大师',
              id: 121829,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/4_3_1.jpg'),
              singePhoto: true,
              name: '职场胸牌',
              tip: '海马体照相馆',
              id: 115889,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/4_5_1.jpg'),
              singePhoto: true,
              name: '形象照',
              tip: '海马体大师',
              id: 115832,
              storeId: 1424,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/4_4_1.jpg'),
              singePhoto: false,
              name: '团队照',
              tip: '海马体照相馆',
              id: 123470,
              storeId: 1002,
              type: 'product'
            },
            {
              img: require('assets/image/limitProduct/4_6_1.jpg'),
              singePhoto: false,
              name: '团队照',
              tip: '海马体大师',
              id: 105827,
              storeId: 1424,
              type: 'product'
            }
          ]
        }
      ],
      showScroll: false,
      top: 0
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.getScroll)
  },
  mounted () {
    let el = this.$refs.nav
    this.top = this.getElementTop(el) + 102
    window.addEventListener('scroll', this.getScroll)
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
  },
  methods: {
    /**
     * [changeIndex 点击后滚动]
     * @param  {[Number]} index [索引]
     */
    changeIndex (index) {
      this.curIndex = index
      window.scrollTo({
        top: this.getElementTop(
          document.querySelector(`#product-bar-${index}`)
        ),
        behavior: 'smooth'
      })
    },
    getElementTop (el) {
      let elemRect = el.getBoundingClientRect()
      return elemRect.top + scrollY - 193
    },

    productDetail (info) {
      this.$router.push({
        name: 'productDetail',
        query: {
          id: info.id,
          storeId: info.storeId,
          type: info.type
        }
      })
    },
    getScroll () {
      this.showScroll = top <= window.scrollY
    }
  }
}
</script>
<style lang="less" scoped>
.product-wrap {
  padding-top: 80px;
  position: relative;
  .product-nav {
    padding-top: 40px;
    background-color: #fff;
    .nav {
      min-height: 23px;
      &.fixed {
        position: fixed;
        padding: 40px 0 60px;
        left: 0;
        top: 0;
        right: 0;
        z-index: 100;
        background-color: #fff;
      }
      ul {
        list-style-type: none;

        li {
          display: inline-block;

          p {
            display: inline-block;
          }

          &::after {
            content: "";
            display: inline-block;
            margin: -2px 22px 0 22px;
            width: 1px;
            height: 14px;
            line-height: 16px;
            background-color: #45454d;
            vertical-align: middle;
          }

          &:last-child::after {
            display: none;
          }
        }

        .active {
          color: #000;
          font-weight: 600;
        }
      }
    }
  }
  .product-margin {
    width: 1488px;
    margin: 0 auto;
    .product-bg {
      width: 1488px;
      position: fixed;
      img {
        width: 100%;
      }
    }
  }
  .product-content-wrap {
      width: 1152px;
      margin: 60px auto;
      position: relative;

      .title {
        padding-bottom: 36px;
        color: #333;
        font-size: 24px;
        font-weight: 600;
        display: inline-block;
      }

      .line {
        position: absolute;
        top: 17px;
        left: 0;
        width: 500px;
        height: 1px;
        background-color: #ebebeb;
      }

      .line-right {
        position: absolute;
        top: 17px;
        right: 0;
        width: 500px;
        height: 1px;
        background-color: #ebebeb;
      }

      .product-content {
        .content {
          position: relative;
          display: inline-block;
          margin: 0 12px 48px;
          width: 46%;
          background-color: #fff;
          text-align: left;
          cursor: pointer;

          img {
            width: 100%;
          }

          .desc {
            padding: 14px 0 20px 14px;

            p {
              color: #16161a;
              font-size: 16px;
              font-weight: 600;
            }

            span {
              color: #919199;
              font-size: 12px;
              font-weight: 400;
            }
          }

          &:hover {
            outline: 12px solid #fff;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);

            &::after {
              content: url("~assets/image/icon/product-arrow.png");
              position: absolute;
              bottom: 32px;
              right: 0;
            }
          }
        }

        .single-content {
          width: 22%;
        }
      }
    }
  .active {
    display: block;
  }
}
</style>
