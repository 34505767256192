<template>
  <div class="choice-product-wrap" v-lazy-animate>
    <LineWrap :titleObj="titleObj"></LineWrap>
    <div class="choice-product-content">
      <div
        class="product-content"
        v-for="(item, index) in imgList"
        :key="index"
        :class="item.showLarge ? 'large-content' : ''"
        v-lazy-animate="index * 0.2"
        @click="getDetail(item)"
      >
        <div class="img-border">
          <img :src="item.img" class="img">
        </div>
        <p>{{ item.title }}</p>
        <span>{{ item.englishName }}</span>
      </div>
    </div>
    <div class="more-product-btn" @click="getMoreProduct"  v-lazy-animate="0.3">
      <p>查看更多</p>
    </div>
  </div>
</template>
<script>
import LineWrap from '../../components/LineWrap'
export default {
  name: 'choiceProduct',
  components: {
    LineWrap
  },
  data () {
    return {
      titleObj: {
        title: '精选产品',
        tip: '生活需要仪式感'
      },
      imgList: [
        {
          img: require('assets/image/choiceProduct/product_1.jpg'),
          showLarge: false,
          title: '证件照',
          englishName: '海马体照相馆',
          id: 105412,
          storeId: 1002,
          type: 'product'
        },
        {
          img: require('assets/image/choiceProduct/product_2.jpg'),
          showLarge: false,
          title: 'VERA WANG合作款轻婚纱',
          englishName: '海马体大师',
          id: 93179,
          storeId: 1424,
          type: 'product'
        },
        {
          img: require('assets/image/choiceProduct/product_3.jpg'),
          showLarge: false,
          title: '国风照',
          englishName: '海马体照相馆',
          id: 107810,
          storeId: 1190,
          type: 'product'
        },
        {
          img: require('assets/image/choiceProduct/product_4.jpg'),
          showLarge: false,
          title: '文艺照',
          englishName: '海马体照相馆',
          id: 116631,
          storeId: 1002,
          type: 'product'
        },
        {
          img: require('assets/image/choiceProduct/product_5.png'),
          showLarge: true,
          title: '结婚登记照',
          englishName: '海马体照相馆',
          id: 116971,
          storeId: 1002,
          type: 'product'
        },
        {
          img: require('assets/image/choiceProduct/product_6.jpg'),
          showLarge: true,
          title: '全家福',
          englishName: '海马体照相馆',
          id: 28410,
          storeId: 1002,
          type: 'product'
        }
      ]
    }
  },
  methods: {
    getMoreProduct () {
      this.$router.push({
        name: 'product'
      })
    },
    getDetail (info) {
      this.$router.push({
        name: 'productDetail',
        query: {
          id: info.id,
          storeId: info.storeId,
          type: info.type
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.choice-product-wrap {
  background-color: #fafafa;
  padding: 70px 0 103px 0;
  .choice-product-content {
    padding: 70px 0 60px;
    width: 1152px;
    margin: 0 auto;
    .product-content {
      position: relative;
      display: inline-block;
      margin: 0 13px 48px;
      width:  22%;
      background-color: #fff;
      border-bottom: 12px solid transparent;
      cursor: pointer;

      .img-border {
        width: 253px;
        height: 383.5px;
        overflow: hidden;
        transition: all 0.3s;

        .img {
          display: block;
          width: 100%;
          height: 100%;
          transition: all 0.3s;
        }
      }
      p {
        color: #16161a;
        font-size: 16px;
        font-weight: 600;
        padding: 14px 0 0 14px;
        text-align: left;
      }
      span {
        display: block;
        margin-left: 14px;
        text-align: left;
        color: #919199;
        font-size: 12px;
        font-weight: 400;
      }

      &:hover {
        outline: 12px solid #fff;
        box-shadow: 0 0 40px rgba(0,0,0,0.5);
        .img {
          transform: scale(1.05);
        }
        &::after {
          content: url("~assets/image/icon/product-arrow.png");
          position: absolute;
          bottom: 8px;
          right: 0;
        }
      }
    }
    .product-content:first-child {
      margin-left: 0;
    }
    .product-content:last-child {
      margin-right: 0;
    }
    .large-content {
      width: 46%;
      margin-left: -12px;
      .img-border {
        width: 530px;
        overflow: hidden;
        transition: all 0.3s;
        img {
          width: 100%;
        }
      }
    }
  }
  .more-product-btn {
    width: 368px;
    height: 52px;
    line-height: 52px;
    margin: 0 auto;
    color: #000;
    font-size: 14px;
    border: 1px solid #000;
    border-radius: 26px;
    cursor: pointer;
    p,span {
      display: inline-block;
    }
    &:hover {
      border: 1px solid #fff;
      background-color: #000;
      color: #fff;
      transition: all .5s;
    }
  }
}
</style>
