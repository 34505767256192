<template>
  <div class="map">
    <el-amap
      :vid="'amapDemo' + id"
      :center="center"
      :zoom="zoom"
      :plugin="plugin"
      style="height:314px;width:686px"
    >
      <el-amap-marker :position="center"></el-amap-marker>
    </el-amap>
  </div>
</template>
<script>
export default {
  name: 'mapWrap',
  props: {
    location: Array,
    id: Number
  },
  data () {
    return {
      zoom: 16, // 地图展示级别
      plugin: ['ToolBar', 'Scale']
    }
  },
  computed: {
    center ({ location }) {
      const [lng, lat] = location
      return [Number(lng), Number(lat)]
    }
  }
}
</script>
<style>
</style>
