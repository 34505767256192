<template>
  <div class="brand-intro-wrap" v-lazy-animate>
    <div class="brand-intro-head"></div>
    <Anchor></Anchor>
    <BrandLine :brandInfo="brandInfo"></BrandLine>
    <BrandActivities></BrandActivities>
    <DevelopmentPath id="anchor-bar-4"></DevelopmentPath>
  </div>
</template>
<script>
import Anchor from './component/Anchor/index'
import BrandLine from './component/BrandLine/index'
import BrandActivities from './brandActivities'
import DevelopmentPath from './developmentPath'
export default {
  name: 'brandIntro',
  components: {
    Anchor,
    BrandLine,
    BrandActivities,
    DevelopmentPath
  },
  data () {
    return {
      brandInfo: [
        {
          showClass: false,
          img: require('assets/image/brand/brain.jpg'),
          showBg: false,
          title: '集团介绍',
          tip: '',
          intro: '杭州海马体摄影有限公司成立于2011年，致力于为顾客提供极致的拍摄体验与优质的摄影服务。现如今美业迅速发展，人们的爱美需求剧增，作为一家专业提供摄影美学的服务型公司，海马体希望通过各个子品牌的运作与所有海马体伙伴的努力，为人们带去更多美好的摄影体验，将我们自身推向更高的高度，直至完成“为世界呈现心动的美好”这个伟大使命。'
        },
        {
          showClass: true,
          img: require('assets/image/brand/brain.png'),
          showBg: true,
          title: '品牌理念',
          tip: '',
          intro: '“海马体”是人脑的记忆中枢，它是用来储存和过滤记忆的部分，我们将品牌命名为“海马体”就是希望通过我们的影像服务为人们记录人生当中的每一个重要时刻，用影像承载更多的记忆和情感，倡导更具“仪式感”的生活。'
        },
        {
          showClass: false,
          img: require('assets/image/brand/brand-culture.png'),
          showBg: false,
          title: '企业文化',
          tip: '为世界呈现心动的美好',
          intro: `成为最懂用户的美学企业，2030年,服务全球10亿用户，让优质摄影体验成为人们的日常生活方式。</br>成为一家有凝聚力、创造力、关注伙伴持续成长的组织，使所有美学追梦者为之向往。</br>成为艺术和科技双轮驱动的创新平台，赋能美学工作者和生态伙伴。`
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.brand-intro-wrap {
  background-color: #fff;
  .brand-intro-head {
    height: 360px;
    background-image: url('~assets/image/brand/head-bg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .circle {
      padding: 17px 0 24px;
      span {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #ff8f00;
        &:last-child {
          background-color: #fff;
          margin-left: 6px;
        }
      }
    }
  }
}
</style>
