<template>
  <div class="reservation-process-wrap" v-lazy-animate="0.2">
    <LineWrap :titleObj="titleObj"></LineWrap>
    <div class="process-wrap">
      <div
        class="process-content"
        v-for="(item, index) in processArr"
        :key="index"
        v-lazy-animate="index * 0.2"
      >
        <img :src="item.img">
        <div v-lazy-animate="index * 0.2" class="item-width">
          <p>{{ item.stepNum }}</p>
          <p class="process-name">{{ item.stepName }}</p>
          <span>{{ item.stepTip }}</span>
          <span class="line"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineWrap from '../../components/LineWrap'

export default {
  name: 'reservationProcess',
  components: {
    LineWrap
  },
  data () {
    return {
      titleObj: {
        title: '预约流程',
        tip: '生活需要仪式感'
      },
      processArr: [
        {
          stepNum: 'Step1',
          stepName: '关注海马体',
          stepTip: '关注海马体公众号了解最近上新产品',
          img: require('assets/image/attentionHimo/himo.png')
        },
        {
          stepNum: 'Step2',
          stepName: '确认产品',
          stepTip: '确认您预约的产品和拍摄门店',
          img: require('assets/image/attentionHimo/chooseStore.png')
        },
        {
          stepNum: 'Step3',
          stepName: '预约时间',
          stepTip: '提前预约避免错过重要时刻',
          img: require('assets/image/attentionHimo/appointment.png')
        },
        {
          stepNum: 'Step4',
          stepName: '确认下单',
          stepTip: '下单后期待最美的自己',
          img: require('assets/image/attentionHimo/confirmOrder.png')
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
  .reservation-process-wrap {
    background-color: #fafafa;
    padding: 60px 0;
    .process-wrap {
      width: 1152px;
      margin: 60px auto 0;
      .process-content {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 25%;
        img {
          width: 320px;
        }
        .item-width {
          width: 320px;
        }
        p{
          font-size: 20px;
          color: #45454d;
          font-weight: 600;
        }
        .process-name {
          font-weight: 400;
          &::after {
            content: '';
            display: block;
            width: 32px;
            height: 1px;
            margin: 8px auto 14px;
            background-color: #000;
          }
        }
        span {
          font-size: 13px;
          color: #919199;
        }
        &::before {
          position: absolute;
          content: '';
          width: 120px;
          height: 1px;
          background-color: #d8d8d8;
          top: 543px;
          right: -74px;
        }
        &:last-child::before {
          display: none;
        }
        &:first-child::before {
          right: -78px;
        }
      }
    }
  }
</style>
