<template>
  <div class="problem">
    <h3>常见问题</h3>
    <img src="~/assets/image/icon/sep.png" alt="" />
    <div class="problem-content">
      <div class="content" v-for="(item, index) in problemList" :key="index">
        <p class="num">{{ index + 1 }}</p>
        <p class="question">{{ item.title }}</p>
        <p class="reply">{{ item.reply }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'problem',
  data () {
    return {
      problemList: [
        {
          title: '到了预约时间点，顾客因有事来不了怎么办？',
          reply: '到了预约时间有事来不了，可以直接打对应门店电话，或者微信联系小海马，说明事由，同时提供新的预约时，然后确认新改下的预约时间。'
        },
        {
          title: '到了后期看片时，顾客对照片不满意可不可以重新拍摄？',
          reply: '从化妆到拍摄结束，每一个环节相对应的化妆师和摄影师都会和顾客确认再进行下一步，如顾客有什么问题请及时提出，我们会有相应的调整。通过顾客确认过的照片，到了看片环节不满意可以要求重修，但是不能重新拍摄。'
        },
        {
          title: '顾客画了妆来门店，还需要化妆吗？如不需要化妆，价格能不能优惠？',
          reply: '这边有提供化妆服务的呢，带妆的情况下我们也是有提供卸妆湿巾，或者化妆师会根据您的妆容帮您补妆哦~ 敏感肌肤建议自带化妆品哦；带妆的情况下我们化妆师也会根据您的拍摄风格进行搭配造型的哦，所以价格无法优惠的哦，请您谅解呢~'
        },
        {
          title: '整个服务流程下来要多久（多久能够拿到照片）？',
          reply: '从化妆造型、拍摄选照、后期修图、后期看片、打印取照整个流程需要2～3小时左右，周末或者节假日需要3～4小时左右。'
        },
        {
          title: '移动端去哪里下载底片？',
          reply: '整个拍摄流程结束后在海马体预约平台“订单”页面中可以下载底片。'
        },
        {
          title: '拍摄的照片一套一共有几张？',
          reply: '除了文艺照四宫格九宫格，普通套系一套精修张数为单张，打印张数会有些不同。请移至海马体预约平台查看详细的套餐介绍。海马体微信公众号“haimati2014”。'
        },
        {
          title: '为什么我依据预约的时间到达门店，不能立马进行拍摄？',
          reply: '预约时间是为了确认您来门店的大致时间帮您预留一个拍摄空位，同一个时间点可能预约2～3个客人，不同套系所需的时间也不一样，有的套系所需时间较长，所以是根据实际到门店的先后顺序来安排。'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
  .problem {
    margin: 45px auto;
    h3 {
      color: #353535;
      font-weight: 700;
      font-size: 15px;
    }
    img {
      padding: 28px 0 40px;
    }
    .problem-content {
      width: 724px;
      margin: 0 auto;
      .content {
        text-align: left;
        .num {
          display: inline-block;
          width: 22px;
          height: 22px;
          padding-top: 1px;
          color: #fff;
          background-color: #16161a;
          border-radius: 50%;
          text-align: center;
        }
        .question {
          display: inline-block;
          padding: 20px 8px;
          font-size: 14px;
          color: #383838;
          font-weight: 700;
        }
        .reply {
          font-size: 14px;
          color: #8b8a8a;
          margin-left: 30px;
        }
      }
    }
  }
</style>
