<template>
  <div class="header-wrap">
    <div class="header-content">
      <div class="logo">
        <img src="~/assets/image/logo/himo-logo.png" alt />
      </div>
      <nav-bar></nav-bar>
    </div>
  </div>
</template>
<script>
import NavBar from './NavBar'
export default {
  components: {
    NavBar
  }
}
</script>
<style lang="less" scoped>
.header-wrap {
  background: #fff;
  width: 100%;

  .header-content {
    position: relative;
    width: 1152px;
    margin: 0 auto;
    text-align: right;
    .logo {
      position: absolute;
      left: 0;
      top: 26px;
      width: 200px;
      img {
        width: 200px;
        height: auto;
      }
    }
  }
}
</style>
