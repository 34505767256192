<template>
  <div class="home" v-lazy-animate="0.2">
    <Carousel></Carousel>
    <NewProduct></NewProduct>
    <ReservationProcess></ReservationProcess>
    <StandardService></StandardService>
    <ChoiceProduct></ChoiceProduct>
  </div>
</template>

<script>
import Carousel from '../components/Carousel'
import ReservationProcess from './home/reservationProcess'
import StandardService from './home/standardService'
import ChoiceProduct from './home/choiceProduct'
import NewProduct from './home/newProduct'
export default {
  name: 'home',
  components: {
    Carousel,
    ReservationProcess,
    StandardService,
    ChoiceProduct,
    NewProduct
  }
}
</script>
<style lang="less" scoped>
.home {
  position: relative;
  min-height: 100vh;
}
</style>
