import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import StoreInfo from '../views/storeInfo'
import BrandIntro from '../views/brandIntro'
import BusinessCooperation from '../views/businessCooperation'
import Product from '../views/product'
import ProductDetail from '../views/product/detail'
import Problem from '../views/home/problem'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'home' }
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/storeInfo',
    name: 'storeInfo',
    component: StoreInfo

  },
  {
    path: '/brandIntro',
    name: 'brandIntro',
    component: BrandIntro
  },
  {
    path: '/businessCooperation',
    name: 'businessCooperation',
    component: BusinessCooperation
  },
  {
    path: '/product',
    name: 'product',
    component: Product
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: ProductDetail
  },
  {
    path: '/problem',
    name: 'problem',
    component: Problem
  }
]

const router = new VueRouter({
  routes
})

export default router
