<template>
  <div class="anchor">
    <div class="anchor-wrap" :class="showScroll ? 'flexd' : ''" ref="anchor">
      <div class="add-margin">
        <div class="anchor-content">
          <div
            class="nav-list"
            v-for="(item, index) in anchorList"
            :key="index"
            @click="click(index)"
            :class="activeIndex === index ? 'active' : ''"
          >
            <img :src="item.activeIcon" v-if="activeIndex === index" />
            <img :src="item.icon" v-else />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'anchor',
  data () {
    return {
      anchorList: [
        {
          title: '集团介绍',
          icon: require('@/assets/image/icon/idea.png'),
          activeIcon: require('@/assets/image/icon/idea-black.png')
        },
        {
          title: '品牌理念',
          icon: require('@/assets/image/icon/lightning.png'),
          activeIcon: require('@/assets/image/icon/lightning-black.png')
        },
        {
          title: '企业文化',
          icon: require('@/assets/image/icon/flag.png'),
          activeIcon: require('@/assets/image/icon/flag-black.png')
        },
        {
          title: '品牌活动',
          icon: require('@/assets/image/icon/start.png'),
          activeIcon: require('@/assets/image/icon/start-black.png')
        },
        {
          title: '发展历程',
          icon: require('@/assets/image/icon/plain.png'),
          activeIcon: require('@/assets/image/icon/plain-black.png')
        }
      ],
      activeIndex: 0,
      showScroll: false,
      showActive: false,
      navTop: 0
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.getScroll)
  },
  mounted () {
    let el = this.$refs.anchor
    this.navTop = this.getElementTop(el) + 150
    window.addEventListener('scroll', this.getScroll)
  },
  methods: {
    /**
     * [click 点击导航滚动到相应的位置]
     * @param  {[Number]} index [对于导航的索引]
     */
    click (index) {
      window.scrollTo({
        top: this.getElementTop(document.querySelector(`#anchor-bar-${index}`)),
        behavior: 'smooth'
      })
    },
    getElementTop (el) {
      let elemRect = el.getBoundingClientRect()
      return elemRect.top + scrollY - 150 - 42
    },
    /**
     * [getScroll 滚动事件]
     */
    getScroll () {
      this.showScroll = this.navTop <= window.pageYOffset
      let lightIndex = 0
      for (let i = 0; i < this.anchorList.length; i++) {
        let top = this.getElementTop(document.querySelector(`#anchor-bar-${i}`))
        if (top <= window.pageYOffset) {
          lightIndex = i
        } else {
          break
        }
      }
      this.activeIndex = lightIndex
    }
  }
}
</script>
<style lang="less" scoped>
  .anchor {
    min-height: 88px;

    .anchor-wrap {
      background-color: #fff;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);

      &.flexd {
        margin: 0 auto;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: #fff;
        z-index: 100;
      }

      .add-margin {
        width: 1152px;
        background-color: #fff;
        margin: 0 auto;

        .placeholder-wrap {
          width: 100%;
          height: 88px;
          line-height: 88px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
        }

        .anchor-content {
          height: 88px;
          line-height: 88px;

          .nav-list {
            display: inline-block;
            width: 20%;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;

            p, img {
              display: inline-block;
            }

            img {
              width: 26px;
              vertical-align: middle;
              margin-right: 10px;
            }
          }

          .active {
            color: #000;
            font-weight: 600;
            transition: opacity 0.2s ease;
          }
        }
      }
    }
  }
</style>
