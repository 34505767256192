<template>
  <div class="product-carousel-wrap">
    <div style="display: flex">
      <div class="swiper-button-prev"></div>
      <div class="swiper-container product-banner-container">
        <div class="swiper-my-pagination"></div>
        <div class="swiper-wrapper">
          <div
            class="swiper-slide carousel-img"
            v-for="(item, index) in imgList"
            :key="index"
            v-lazy-animate="index * 0.3"
          >
            <img :src="item.img" alt="img" @click="jumpDetail(item)" />
            <div v-lazy-animate="index * 0.3 + 0.1">
              <p class="title" @click="jumpDetail(item)">{{ item.name }}</p>
              <p class="tip" @click="jumpDetail(item)">{{ item.tip }}</p>
              <div class="detail-btn" @click="jumpDetail(item)">
                <p>查看详情</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
export default {
  name: 'product-carousel',
  data () {
    return {
      mySwiper: null, // 轮播初始化
      imgList: [
        {
          img: require('assets/image/newProduct/certificate.jpg'),
          name: '证件照',
          tip: '专业更致美，在方寸之间记录生活的仪式感和人生的重要时刻',
          id: 105412,
          storeId: 1002,
          type: 'product'
        },
        {
          img: require('assets/image/newProduct/occupation.jpg'),
          name: '职业形象照',
          tip: '职场中，专业干练的职业形象展示，对您的事业至关重要。职业形象照，展现你的职业态度和专业气质。',
          id: 115869,
          storeId: 1002,
          type: 'product'
        },
        {
          img: require('assets/image/newProduct/literary.jpg'),
          name: '文艺照',
          tip: '用表情和神态表达你的个性，用镜头和照片定格下你张扬的宣言。',
          id: 116631,
          storeId: 1002,
          type: 'product'
        },
        {
          img: require('assets/image/newProduct/flower-face.jpg'),
          name: '花颜照',
          tip: '春回梦醒，潋滟一身花色',
          id: 27203,
          storeId: 1326,
          type: 'product'
        },
        {
          img: require('assets/image/newProduct/wedding.jpg'),
          name: '轻婚纱',
          tip: '穿上婚纱，向你展示我一生最美的模样',
          id: 27184,
          storeId: 1326,
          type: 'product'
        }
      ],
      productList: [
        {
          title: '证件照'
        },
        {
          title: '职业形象照'
        },
        {
          title: '文艺照'
        },
        {
          title: '花颜照',
          tip: '春回梦醒，潋滟一身花色'
        },
        {
          title: '轻婚纱',
          tip: '穿上婚纱，向你展示我一生最美的模样'
        }
      ]
    }
  },
  mounted () {
    // 页面挂载完成后展示轮播
    this.mySwiper = new Swiper('.product-banner-container', {
      loop: false,
      autoplay: false,
      mousewheel: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-my-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          let text = ''
          switch (index) {
            case 0:text = '证件照'; break
            case 1:text = '职业形象照'; break
            case 2:text = '文艺照'; break
            case 3:text = '花颜照'; break
            case 4:text = '轻婚纱'; break
          }
          return `<span class="${className}">${text}</span>`
        }
      }
    })
  },
  methods: {
    /**
     * [jumpDetail 点击进入详情]
     * @param  {[info]} info [图片或文字或查看详情按钮]
     */
    jumpDetail (info) {
      this.$router.push({
        name: 'productDetail',
        query: {
          id: info.id,
          storeId: info.storeId,
          type: info.type
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .product-carousel-wrap {
    width: 1200px;
    margin: 0 auto;
    .product-banner-container {
      .carousel-img {
        width: 938px;
        font-size: 0;

        img {
          cursor: pointer;
        }

        .title {
          cursor: pointer;
          padding: 50px 0 8px;
          color: #333;
          font-size: 24px;
          font-weight: 600;
        }
        .tip {
          cursor: pointer;
          padding-bottom: 50px;
          color: #919199;
          font-size: 14px;
          font-weight: 400;
        }
        .detail-btn {
          width: 368px;
          height: 52px;
          line-height: 52px;
          margin: 0 auto 80px;
          color: #000;
          font-size: 14px;
          border: 1.5px solid #000;
          border-radius: 26px;
          cursor: pointer;
          p,span {
            display: inline-block;
          }
          &:hover{
            transition: all .5s;
            background-color: #000;
            color: #fff;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
</style>
<style lang="less">
  .swiper-container .swiper-pagination-bullet {
    width: auto;
    height: auto;
    border-radius: 0;
    background: transparent;
    color: #45454D;
    font-size: 16px;
    opacity: 1;
    &::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 16px;
      background-color: #45454D;
      vertical-align: text-top;
      margin-left: 20px;
      margin-top: 2px;
    }

    &:last-child::after {
      display: none;
    }
  }
  .swiper-container .swiper-pagination-bullet-active {
    background: none;
    font-weight: 600;
    color: #000;
  }
  .product-carousel-wrap .swiper-button-prev, .product-carousel-wrap  .swiper-button-next {
    position: unset;
    margin: 0 54px 212px;
    height: auto;
    outline: 0;
    color: #45454d;
  }
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    padding: 40px 0;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 12px!important;
  }
</style>
