<template>
  <div class="detail-wrap" v-bind:class="{ show: !!mainImage }">
    <div class="detail-content">
      <img class="head-img" :src="`${host}${mainImage}`" />
      <!-- 蓝标产品 -->
      <div class="blue-product" :style="styleObject" v-if="blueProduct">
        <div class="blue-name">
          <p class="blue-title">{{ titleObj.title }}</p>
          <p class="blue-price"><span>￥</span>{{ price }}<span>起</span><i class="circle"></i></p>
        </div>
        <div class="buy">
          <p>立即选购</p>
          <i></i>
        </div>
        <div class="code">
          <img class="code-img" src="~/assets/image/icon/review-code.png" />
          <p>微信扫码预约</p>
        </div>
      </div>
      <!-- 大师产品 -->
      <div v-if="!blueProduct">
        <p class="title">{{ titleObj.title }}</p>
        <p class="price"><span>￥</span>{{ price }}<span>起</span></p>
        <div class="line"></div>
      </div>
      <div v-if="isShowStr" class="text-desc" :class="blueProduct ? 'blue-text-desc' : ''">
        <p class="text">服务&#38;交付标准</p>
        <i :class="blueProduct ? 'blue-icon' : 'gold-icon'"></i>
      </div>
      <div class="photograph-desc">
        <ul>
          <li v-if="serviceExplanation.hz">
            <img class="detail-img-blue" v-if="blueProduct" :src="blueImg.makeup" />
            <img class="detail-img-gold" v-else :src="goldImg.makeup" />
            <span>{{ serviceExplanation.hz }}</span>
          </li>
          <li v-if="serviceExplanation.fz">
            <img class="detail-img-blue" v-if="blueProduct" :src="blueImg.clothing" />
            <img class="detail-img-gold" v-else :src="goldImg.clothing" />
            <span>{{ serviceExplanation.fz }}</span>
          </li>
          <li v-if="serviceExplanation.xt">
            <img class="detail-img-blue" v-if="blueProduct" :src="blueImg.photograph" />
            <img class="detail-img-gold" v-else :src="goldImg.photograph" />
            <span>{{ serviceExplanation.xt }}</span>
          </li>
          <li v-if="serviceExplanation.ps">
            <img class="detail-img-blue" v-if="blueProduct" :src="blueImg.ps" />
            <img class="detail-img-gold" v-else :src="goldImg.ps" />
            <span>{{ serviceExplanation.ps }}</span>
          </li>
          <li v-if="serviceExplanation.cy">
            <img class="detail-img-blue" v-if="blueProduct" :src="blueImg.print" />
            <img class="detail-img-gold" v-else :src="goldImg.print" />
            <span>{{ serviceExplanation.cy }}</span>
          </li>
        </ul>
      </div>
      <div v-if="!blueProduct" class="review-btn">
        <p>立即预约</p>
        <div class="code">
          <img class="code-img" src="~/assets/image/icon/review-code.png" />
          <p>微信扫码预约</p>
        </div>
      </div>
      <div
        class="service-desc"
        :class="[{ 'blue-desc': blueProduct, 'blue-desc-other': (!isShowStr && blueProduct) }]"
      >
        <div class="content">
          <div class="samples" v-for="(item, index) in sampleInfo" :key="index">
            <img :src="`${host}${item}`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
export default {
  name: 'detail',
  data () {
    return {
      titleObj: {
        title: '',
        tip: ''
      },
      showCircle: true,
      loading: false,
      detailInfo: {},
      photographDesc: {},
      price: '',
      storeType: '',
      host: '',
      mainImage: null,
      sampleInfo: [],
      serviceExplanation: {},
      styleObject: {
        position: 'absolute',
        left: '32px',
        top: '900px'
      },
      blueImg: {
        makeup: require('assets/image/productDetail/blue_makeup.png'),
        clothing: require('assets/image/productDetail/blue_clothing.png'),
        photograph: require('assets/image/productDetail/blue_photograph.png'),
        ps: require('assets/image/productDetail/blue_ps.png'),
        print: require('assets/image/productDetail/blue_print.png')
      },
      goldImg: {
        makeup: require('assets/image/productDetail/gold_makeup.png'),
        clothing: require('assets/image/productDetail/gold_clothing.png'),
        photograph: require('assets/image/productDetail/gold_photograph.png'),
        ps: require('assets/image/productDetail/gold_ps.png'),
        print: require('assets/image/productDetail/gold_print.png')
      }
    }
  },
  computed: {
    blueProduct () {
      return this.storeType === 'himo_blue'
    },
    isShowStr () {
      return Boolean(this.serviceExplanation.hz || this.serviceExplanation.fz || this.serviceExplanation.cy || this.serviceExplanation.ps)
    }
  },
  async mounted () {
    await this.getDetail()
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
  },
  methods: {
    /**
     * [getDetail 获取产品详细信息]
     */
    async getDetail () {
      const domain = process.env.VUE_APP_API_HOST
      let detail = {}
      if (this.$route.query.type === 'product') {
        detail = await axios.get(`${domain}/appointment_platform/product/product/category_product`, {
          params: {
            categoryId: this.$route.query.id,
            storeId: this.$route.query.storeId
          }
        })
      } else {
        detail = await axios.get(`${domain}/himo_product/user/product/detail`, {
          params: {
            product_id: this.$route.query.id,
            store_id: this.$route.query.storeId
          }
        })
      }
      this.detailInfo = detail.data.msg
      this.titleObj.title = this.detailInfo.fullName
      this.titleObj.tip = this.detailInfo.engName
      this.price = _.get(this.detailInfo, 'children[0].price.price')
      this.storeType = _.get(this.detailInfo, 'module')
      this.host = _.get(this.detailInfo, 'host')
      this.mainImage = _.get(this.detailInfo, 'mainImage') || _.get(this.detailInfo, 'productImgForInfo.imgForList')
      this.serviceExplanation = _.get(this.detailInfo, 'serviceExplanation.serviceExplanationItem') || {}
      this.sampleInfo = _.get(this.detailInfo, 'sampleInfo.samples[0].urls')
      const img = new Image()
      // 改变图片的src
      img.src = `${this.host}${this.mainImage}`
      // 加载完成执行
      img.onload = () => {
        const heightValue = Number(750 * img.height / img.width - 107)
        this.styleObject.top = `${heightValue}px`
      }
    }
  }
}
</script>
<style lang="less" scoped>
  img {
    display: block;
  }
  .detail-wrap {
    padding: 78px 0;
    margin: 0 auto;
    min-height: 1000px;
    opacity: 0;
    transition: opacity 2.8s ease;

    &.show{
      opacity: 1;
    }
    .detail-content {
      position: relative;
      width: 750px;
      margin: 0 auto;
      .head-img {
        width: 100%;
        background-color: #919199;
      }
      /* 蓝标产品样式 */
      .blue-product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 686px;
        padding: 53px 30px 45px;
        background-color: #fff;
        border-radius: 28px;
        box-shadow: 0px 9px 30px 0px rgba(0, 0, 0, 0.1);
        .blue-name {
          display: flex;
          justify-content: space-between;
          margin-bottom: 52px;
          background-color: #fff;
          .blue-title {
            color: #000;
            font-family: PingFang SC;
            font-size: 40px;
            font-weight: 600;
            text-align: left;
          }
          .blue-price {
            position: relative;
            color: #000;
            font-family: D-DIN;
            font-weight: bold;
            font-size: 40px;
            text-align: left;
            span {
              margin-left: 22px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 34px;
            }
            .circle {
              position: absolute;
              top: 0;
              right: -10px;
              width: 54px;
              height: 54px;
              background: url("~assets/image/icon/circle.png");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
            }
          }
        }
        .buy {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px 22px 25px;
          border-radius: 8px;
          background-color: #f5f5f5;
          p {
            font-size: 28px;
            color: #000;
          }
          i {
            width: 32px;
            height: 17px;
            background: url("~assets/image/icon/blue-right-arrow.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .code {
          position: absolute;
          width: 253px;
          height: 253px;
          top: 150px;
          right: -258px;
          background-image: url("~assets/image/icon/tool.png");
          background-size: 253px;
          background-repeat: no-repeat;
          opacity: 0;
          .code-img {
            margin: 20px 0 0 40px;
            width: 191px;
            height: 191px;
            vertical-align: text-top;
          }
          p {
            padding: 2px 0 0 10px;
            height: 22px;
            line-height: 22px;
            font-size: 16px;
            color: #16161a;
            font-weight: 400;
          }
        }
        &:hover {
          .code {
            opacity: 1;
            transition: all 2s;
          }
        }
      }
      /* 大师产品样式 */
      .title {
        margin: 40px 0 40px 40px;
        color: #000;
        font-family: PingFang SC;
        font-size: 44px;
        font-weight: 600;
        text-align: left;
      }
      .price {
        margin: 0 0 50px 40px;
        color: #000;
        font-family: D-DIN;
        font-weight: bold;
        font-size: 36px;
        text-align: left;
        span {
          margin-left: 13px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 28px;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background: #ddd;
      }
      .text-desc {
        display: flex;
        padding: 45px 40px 0;
        justify-content: space-between;
        align-items: center;
        .text {
          font-family: PingFang SC;
          font-size: 40px;
          font-weight: 600;
          color: #000;
        }
        .blue-icon {
          width: 32px;
          height: 17px;
          background: url("~assets/image/icon/blue-right-arrow.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .gold-icon {
          width: 27px;
          height: 31px;
          background: url("~assets/image/icon/right-arrow.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .blue-text-desc {
        margin-top: 205px;
      }
      .photograph-desc {
        .detail-img-blue {
          width: 104px;
        }
        .detail-img-gold {
          width: 85px;
        }
        ul {
          display: flex;
          justify-content: space-between;
          padding: 0 40px;
          li {
            display: inline-block;
            padding: 40px 0 0;
            span {
              display: block;
              white-space:nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #000;
              font-size: 15px;
            }
          }
        }
      }
      .service-desc {
        .title {
          position: relative;
          text-align: left;
          .name {
            margin-left: 20px;
            color: #333;
            font-size: 24px;
            font-weight: 600;

            &::before{
              position: absolute;
              top: 5px;
              left: 0;
              content: "";
              width: 4px;
              height: 24px;
              background-color: #c5a563;
            }
          }
          .price {
            position: absolute;
            right: 0;
            top: 0;
            color: #c5a563;
            font-size: 24px;
            font-weight: 600;
          }
        }
        .content {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          line-height: 28px;
          img {
            width: 100%;
          }
          ul {
            padding-bottom: 30px;
            li {
              color: #c5a563;
              p {
                color: #45454d;
              }
            }
          }
        }
      }
      .blue-desc {
        margin-top: 80px;
        &.blue-desc-other {
          margin-top: 275px !important;
        }
        .title {
          .name {
            &::before {
              background-color: #000;
            }
          }
          .price {
            color: #000;
          }
        }
        .content {
          ul {
            li {
              color: #000;
              p {
                color: #45454d;
              }
            }
          }
        }
      }
      .review-btn {
        position: relative;
        margin: 56px auto;
        width: 675px;
        height: 70px;
        line-height: 70px;
        border-radius: 10px;
        border: 2px solid #8a8173;
        color: #fff;
        background-color: #8a8173;
        font-size: 24px;
        font-weight: 600;

        .code {
          position: absolute;
          width: 253px;
          height: 253px;
          top: -10px;
          right: -258px;
          background-image: url("~assets/image/icon/tool.png");
          background-size: 253px;
          background-repeat: no-repeat;
          opacity: 0;
          .code-img {
            margin: 20px 0 0 40px;
            width: 191px;
            height: 191px;
            vertical-align: text-top;
          }
          p {
            padding: 2px 0 0 10px;
            height: 22px;
            line-height: 22px;
            font-size: 16px;
            color: #16161a;
            font-weight: 400;
          }
        }
        &:hover {
          .code {
            opacity: 1;
            transition: all 2s;
          }
        }
      }
    }
  }
</style>
