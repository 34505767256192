<template>
  <div class="nav-bar">
    <ul class="tabs">
      <li
        v-for="(item, index) in navList"
        :class="{ active: currentIndex === index }"
        :key="index"
        @click="goto(index, item.router ? item.router : item.link)"
      >
        <div class="tab-wrap">
          <p class="tab">{{ item.name }}</p>
          <p class="change-color">{{ item.name }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'nav-bar',
  data () {
    return {
      navList: [
        {
          name: '首页',
          router: 'home'
        },
        {
          name: '集团介绍',
          router: 'brandIntro'
        },
        {
          name: '门店信息',
          router: 'storeInfo'
        },
        {
          name: '企业合作',
          router: 'businessCooperation'
        },
        {
          name: '人才招聘',
          link: 'https://mainto.zhiye.com/Social'
        }
      ],
      currentIndex: 0
    }
  },
  watch: {
    '$route' () {
      this.getCurrentNav()
    }
  },
  mounted () {
    this.getCurrentNav()
  },
  methods: {
    /**
     * [goto 点击导航后进入相应的路由或链接]
     * @param  {[String]}  router [路由或链接]
     */
    goto (index, router) {
      if (index !== 4) {
        this.currentIndex = index
      }
      if (router === 'https://mainto.zhiye.com/Social') {
        window.open(router, '_blank')
      } else {
        this.getCurrentNav()
        this.$router.push({
          name: router
        })
      }
    },
    /**
     * [getCurrentNav 获取当前导航]
     */
    getCurrentNav () {
      this.currentIndex = -1
      _.map(this.navList, (item, index) => {
        // 将this.$route.path中所有的/转为''
        if (this.$route.path.replace(/\//g, '') === item.router) {
          this.currentIndex = index
        } else if (this.$route.path === '/') {
          this.currentIndex = 0
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.nav-bar {
  display: inline-block;
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    .active {
      color: #fff;
      background-color: #000;
      font-weight: 600;
      .change-color{
        color: #fff !important;
      }
      &:hover p{
        color: #fff;
        font-weight: 600;
        transform: translate3d(0,-100%,0);
      }
      &:hover:before {
        color: #fff;
        content: attr(data-title);
        display: inline-block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        text-align: center;
      }

      &::after {
        display: none;
      }
    }
    li {
      position: relative;
      display: inline-block;
      cursor: pointer;
      padding: 33px 28px;
      color: #16161a;
      background-color: #fff;
      font-size: 16px;
      font-weight: 400;
      transition: all 0.36s ease;
      .tab-wrap {
        height: 22px;
        overflow: hidden;
        .change-color{
          color: #000;
        }
        p {
          position: relative;
          width: 100%;
          height: 100%;
          display: block;
          transition: transform 0.3s linear;
        }
      }
      &::after {
        position: absolute;
        right: 0;
        top: 33px;
        content: "";
        width: 1px;
        height: 22px;
        background-color: #e3e3e6;
        z-index: 9;
      }

      &:first-child::before {
        display: none;
      }

      &:hover p{
        color: #000;
        font-weight: 600;
        transform: translate3d(0,-100%,0);
      }
      &:hover:before {
        color: #000;
        content: attr(data-title);
        display: inline-block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
