<template>
  <div class="brand-activities-wrap">
    <div class="brand-activities-content">
      <div id="anchor-bar-3" class="brand-activities-title">
        <BrandLine :centerShow="centerShow" :brandInfo="brandInfo"></BrandLine>
      </div>
      <div
        class="brand-activities-info"
        v-for="(item, index) in introList"
        :key="index"
        v-lazy-animate="index * 0.2"
      >
        <div class="brand-activities-img">
          <img :src="item.img" alt="" />
        </div>
        <div class="brand-activities-intro" :class="item.showLeft ? 'intro-left' : ''">
          <p>{{ item.title }}</p>
          <span>{{ item.desc }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BrandLine from './component/BrandLine/index'
export default {
  name: 'brandActivities',
  components: {
    BrandLine
  },
  data () {
    return {
      centerShow: true,
      brandInfo: [
        {
          title: '品牌活动'
        }
      ],
      introList: [
        {
          showLeft: true,
          title: '海马体大师x VERA WANG',
          img: require('@/assets/image/brand/verawang.jpg'),
          desc: '每个女生都有一个VERA WANG的婚纱梦，而海马体一直致力于记录生活中的各种仪式感。在2020年海马体大师店推出了VERA WANG合作款轻婚纱照，让顾客在海马体即能穿上殿堂级地位的VERA WANG婚纱。我们希望，轻婚纱不仅是一张薄薄的照片而已，更是一场为女生圆梦的仪式。'
        },
        {
          showLeft: false,
          title: '公益活动',
          img: require('@/assets/image/brand/happiness.png'),
          desc: '海马体照相馆致力于社会公益项目。从2018年起，我们就先后在北京大兴机场、上海浦东机场、杭州市民政局、四川乡村小学落地「幸福照相馆」公益项目。同时，我们也和腾讯公益、红粉笔课堂、Ubras粉红丝带等各类品牌合作拍摄公益，关注不同群体，将影像记录的温暖带到各个地方。'
        },
        {
          showLeft: true,
          title: '跨界活动',
          img: require('@/assets/image/brand/adm.jpg'),
          desc: '海马体致力于与不同风格的品牌碰撞跨界。2022年我们推出了哈利波特系列，实现大家的魔法梦想；2023年我们推出了迪士尼公主系列，让每个人都能在现实生活中变成公主。不仅这些，我们还与时尚芭莎、星巴克、VERAWANG、王者荣耀、综艺节目《乐队的夏天》等各类品牌进行破圈跨界，旨在「万物皆可海马体」。'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.brand-activities-wrap {
  background-color: #fafafa;
  padding-bottom: 120px;
  text-align: center;
  .brand-activities-content {
    width: 1152px;
    padding-top: 100px;
    margin: 0 auto;
    .brand-activities-info {
      padding-top: 120px;
      .brand-activities-img {
        position: relative;
        display: inline-block;
        width: 538px;
        height: 360px;
        background-color: #f2f2f5;
        img {
          position: absolute;
          right: 14px;
          bottom: 14px;
          width: 100%;
        }
      }
      .brand-activities-intro{
        display: inline-block;
        width: 480px;
        padding: 38px 0;
        margin-left: 130px;
        vertical-align: top;
        p{
          color: #45454d;
          font-size: 36px;
          font-weight: 600;
          padding-bottom: 10px;
          text-align: left;
        }
        span{
          display: block;
          color: #45454d;
          padding-top: 20px;
          font-size: 16px;
          line-height: 40px;
          text-align: left;
        }
      }
      .intro-left {
        float: left;
        margin-left: 0;
        margin-right: 130px;
      }
    }
  }
}
</style>
