<template>
  <div class="carousel-wrap">
    <div class="swiper-container banner-container">
      <div class="swiper-wrapper">
        <div
          @click="productDetail(item)"
          class="swiper-slide carousel-img"
          v-for="(item, index) in imgList"
          :key="index"
          v-bind:style="{ backgroundImage:`url('${item.img}')` }"
        ></div>
      </div>
    </div>
    <div class="carousel-preview">
      <div class="carousel-window" v-bind:class="{ show: showPreview }">
        <div class="swiper-container preview-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide preview-img" v-for="(item, index) in imgList" :key="index">
              <img :src="item.img" alt="img" />
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-btn" @mouseout="outPreview">
        <p class="swiper-left" @mouseenter="enterLeft">
          <img @mouseenter="enterRight" src="~/assets/image/icon/banner-arrow.png" />
        </p>
        <p class="swiper-right" @mouseenter="enterRight">
          <img @mouseenter="enterRight" src="~/assets/image/icon/banner-arrow.png" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'

export default {
  name: 'carousel',
  data () {
    return {
      length: 0,
      imgList: [
        {
          img: require('@/assets/image/banner/banner_1.jpg'),
          showLarge: false,
          title: '证件照',
          englishName: 'MEMORIES',
          id: 105412,
          storeId: 1002,
          type: 'product'
        },
        {
          img: require('@/assets/image/banner/banner_2.jpg'),
          showLarge: false,
          title: '轻婚纱',
          englishName: 'MEMORIES',
          id: 93179,
          storeId: 1424,
          type: 'product'
        },
        {
          img: require('@/assets/image/banner/banner_3.jpg'),
          showLarge: false,
          title: '文艺照',
          englishName: 'MEMORIES',
          id: 102313,
          storeId: 1002,
          type: 'product'
        }
      ],
      activeIndex: 0,
      cycleNum: 0,
      currentImg: '',
      showPreview: false,
      previewSwiper: null,
      mySwiper: null,
      offset: 0
    }
  },
  mounted () {
    this.previewSwiper = new Swiper('.preview-container', {
      loop: true,
      mousewheel: false
    })

    this.mySwiper = new Swiper('.banner-container', {
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        autoHeight: false,
        disableOnInteraction: false
      },

      on: {
        slideChange: () => {
          if (this.offset === -1) {
            this.previewSwiper.slidePrev()
          } else {
            this.previewSwiper.slideNext()
          }
        }
      },
      navigation: {
        nextEl: '.swiper-right',
        prevEl: '.swiper-left'
      }
    })
  },
  methods: {
    enterLeft () {
      this.showPreview = true
      this.offset = -1
      this.previewSwiper.slideToLoop(this.mySwiper.realIndex - 1)
    },
    enterRight () {
      this.showPreview = true
      this.offset = 1
      this.previewSwiper.slideToLoop(this.mySwiper.realIndex + 1)
    },
    outPreview () {
      this.showPreview = false
    },
    productDetail (info) {
      this.$router.push({
        name: 'productDetail',
        query: {
          id: info.id,
          storeId: info.storeId,
          type: info.type
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.carousel-wrap {
  position: relative;
  width: 100%;

  .carousel-img {
    width: 100%;
    height: 600px;
    font-size: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .carousel-preview {
    position: absolute;
    z-index: 100;
    bottom: 0;
    right: 20%;

    .carousel-window {
      position: relative;
      opacity: 0;
      display: inline-block;
      width: 156px;
      height: 68px;
      vertical-align: middle;
      border-right: 1px solid #e3e3e6;
      transition: opacity 0.2s ease;

      &.show {
        opacity: 1;
      }

      .preview-img {
        position: relative;
        width: 156px;
        height: 68px;
        overflow: hidden;
        img {
          position: absolute;
          left: 50%;
          margin-left: -136px;
          height: 68px;
        }
      }
    }

    .carousel-btn {
      background-color: #fff;
      display: inline-block;
      height: 68px;
      line-height: 68px;
      vertical-align: middle;
      .swiper-left {
        padding-left: 30px;
        img {
          display: inline-block;
          transform: rotate(180deg);
          padding-top: 1px;
          vertical-align: middle;
        }
      }
      .swiper-right {
        padding: 0 30px 0 20px;
        img {
          display: inline-block;
          vertical-align: middle;
        }
      }
      p {
        display: inline-block;
        outline: 0;
      }

      p:first-child:after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 24px;
        margin-left: 20px;
        background-color: #e3e3e6;
        vertical-align: middle;
      }
    }
  }
}
</style>
